import React, { Component } from "react";
import CommonTaskComponent from "./common-tasks-componnet";

import { getRunTasks } from "./template-view-apis";
import RunTasks from "./runs-tasks";

class IntegrationRuns extends Component {
  state = {
    showTasks: false,
    fetchingTasks: false,
    tasks: {},
  };
  toggleTasks = () => {
    const { showTasks } = this.state;
    const { integrationId, templateId, id } = this.props;
    if (showTasks) {
      this.setState({ showTasks: false });
    } else {
      this.setState({ fetchingTasks: true });
      getRunTasks(templateId, integrationId, id).then((res) => {
        this.setState({
          tasks: res.data,
          showTasks: true,
          fetchingTasks: false,
        });
      });
    }
  };
  render() {
    const { steps } = this.state.tasks;
    const { showTasks, fetchingTasks } = this.state;
    const { showInternalSteps, integrationId, templateId, id } = this.props;

    return (
      <div className={"integration-runs-task-container"}>
        <div className={"integration-runs"} onClick={this.toggleTasks}>
          <CommonTaskComponent {...this.props} />
        </div>
        {showTasks && (
          <RunTasks
            steps={steps}
            root={true}
            showInternalSteps={showInternalSteps}
            integrationId={integrationId}
            templateId={templateId}
            step_guid={id}
            // key={id}
          />
        )}
        {fetchingTasks && (
          <div
            className={"spinner-container"}
            style={{ marginTop: 0, height: 73 }}
          >
            <div className="integry-spinner-sm" />
          </div>
        )}
      </div>
    );
  }
}

export default IntegrationRuns;
