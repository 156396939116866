// WorkspaceContext.ts
import React, { createContext, useContext, useEffect, useState } from "react";
import { App } from "types/app";
import { DefaultWorkspaceApp } from "types/integry-user";
import { useStore } from "react-redux";

import { useFetchUserQuery } from "utils/user/user-api";

interface WorkspaceContextValue {
  workspaceApp: App | DefaultWorkspaceApp | null;
}

const WorkspaceContext = createContext<WorkspaceContextValue | undefined>(
  undefined
);

const useWorkspaceAppContext = (): WorkspaceContextValue => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error("useWorkspace must be used within a WorkspaceProvider");
  }
  return context;
};

const WorkspaceAppProvider: React.FC = ({ children }) => {
  const reduxStore = useStore();
  const [workspaceApp, setWorkspaceApp] = useState<
    App | DefaultWorkspaceApp | null
  >(null);
  const { data: user } = useFetchUserQuery();

  useEffect(() => {
    const { globallySelectedApp, myApps } = reduxStore.getState() as {
      globallySelectedApp: number;
      myApps: {
        isFetching: boolean;
        items: App[];
      };
    };
    if (!myApps.isFetching) {
      const app =
        myApps.items.find((ap) => ap.id === globallySelectedApp) || null;
      setWorkspaceApp(app);
    } else if (user) {
      setWorkspaceApp(user.account?.default_workspace_app || null);
    }
  }, [reduxStore, user]);

  return (
    <WorkspaceContext.Provider value={{ workspaceApp }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export { WorkspaceAppProvider, useWorkspaceAppContext };
