/* eslint-disable react/prop-types */
import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { FeatureFlag } from "react-launch-darkly";
import { connect } from "react-redux";
import { Beforeunload } from "react-beforeunload";

import DashboardWrapper from "components/header-v3/dashboard-wrapper";
import { updateOnboardingState } from "store/actions/onboarding-state";
import CanduDashboard from "components/candu-dashboard";
import PageTitle from "./page-title";
import { getCompanyName } from "utils/functions";
import RemainingTrialDaysPopup from "./turbo.v1/trial-period";
import TrialPeriodBanner from "./turbo.v1/trial-period/trial-period-banner";
import lang from "../utils/lang/en";

import CalendlyWidget from "./turbo.v1/calendly-widget";

import config from "../utils/config";
import Settings from "../features/account-settings/settings";

import NoMatchFound from "./no-match-found";
import IntegryLoader from "./integry-loader";

import Urls from "../utils/router-urls";
import { integrationViewTypeEnum } from "../legacy-features/integration-view/interfaces";

import { fetchAndSetSystemGeneratedObjects } from "features/account-settings/payloads-management/object-list/api";
import { WorkspaceAppProvider } from "context/workspaceAppContext";
import { FeatureFlagsProvider } from "context/featureFlagsContext";
import urls from "../utils/router-urls";
import IntegrationRuns from "legacy-features/templates/v2/template-view/integration-runs";

const Templates = lazy(() =>
  import("../legacy-features/templates/v1/templates")
);
const TemplatesV2 = lazy(() =>
  import("../legacy-features/templates/v2/templates")
);
const TemplatesV3 = lazy(() =>
  import("../legacy-features/templates/v3/templates")
);
const TemplatesV4 = lazy(() =>
  import("../legacy-features/templates/v4/templates")
);
const TemplatesV5 = lazy(() =>
  import("../legacy-features/templates/v5/templates")
);
const TemplatesV6 = lazy(() =>
  import("../legacy-features/templates/v6/templates")
);
const TemplatesV7 = lazy(() =>
  import("../features/templates/template-builder-v7/templates")
);
const EndUserAnalytic = lazy(() =>
  import("../legacy-features/end-user-analytics/index")
);
const Apps = lazy(() => import("./apps/apps"));
const AppsV3 = lazy(() => import("./apps-v3/apps"));
const AppsV4 = lazy(() => import("./apps-v4/apps"));
const Bundles = lazy(() => import("./bundles/bundles"));
const BundlesV2 = lazy(() => import("./bundles-v2/bundles"));
const BundlesV3 = lazy(() => import("./bundles-v3/bundles"));
const MarketPlaceSettings = lazy(() =>
  import("./bundles-v3/deployments/marketplace-settings")
);
const ConnectedSdkPreview = lazy(() =>
  import("./bundles-v3/deployments/marketplace-settings/embed/sdk-preview")
);
const Insights = lazy(() => import("../legacy-features/insights/insights"));
// const Marketplace = lazy(() => import("./turbo.v2/marketplace"));
const AppPage = lazy(() => import("./turbo.v2/app-page"));
const MarketplaceV2 = lazy(() =>
  import("./turbo.v2/marketplace/index.new.tsx")
);
const ActionsTurbo = lazy(() => import("./turbo.v2/actions-container"));

const IntegrationViewWrapper = lazy(() =>
  import(
    "../legacy-features/integration-view/components/skeleton/integration-view-wrapper"
  )
);
const Integrations = lazy(() => import("legacy-features/integrations"));
const EndUsers = lazy(() => import("features/end-users"));
const EmbedPreview = lazy(() => import("features/embed-preview"));
const Marketplace = lazy(() => import("features/markteplace/v2/"));
const MarketplaceV3 = lazy(() => import("features/markteplace/v3/"));

const IntegrationsV2 = lazy(() => import("features/integrations/components"));
const IntegrationRunsV2 = lazy(() =>
  import("features/integration-runs/components")
);
const Users = lazy(() => import("features/users"));

const MainRouting = (props) => {
  const { user, hasIntercom, shouldInitializeIntercom, location, show404Page } =
    props;
  const {
    email,
    // username,
    id,
    date_joined,
    intercom_hash,
    account_id,
    account_name,
    first_name,
    last_name,
    company_size,
    company_link,
    platform_type,
    role,
  } = user;
  const userObj = {
    email,
    username: user.username,
    id,
    dateJoined: date_joined,
    intercomHash: intercom_hash,
    accountId: account_id,
    accountName: account_name,
    firstName: first_name,
    lastName: last_name,
    companySize: company_size,
    companyLink: company_link,
    platformType: platform_type,
    role,
  };
  if (hasIntercom & shouldInitializeIntercom) {
    if (typeof email === "string" && !email.includes("mailosaur.net")) {
      // Do not initilize intercom if account is of automated user. This prevent sending Signup notifications for automated test on slack channel
      // We could not use REACT_APP_CYPRESS env variable because Automated test signup on Live app and then use login Creds on local server.
      const { boot } = useIntercom();
      const onboardingInfo = JSON.parse(
        localStorage.getItem("onboardingInfo") || "{}"
      );
      boot({
        horizontalPadding: 50,
        userId: id,
        email,
        name: `${first_name} ${last_name}`,
        createdAt: date_joined,
        userHash: intercom_hash,
        company: {
          name: getCompanyName(account_name),
          website: company_link,
          industry: platform_type,
          companyId: company_link || account_name,
        },
        customAttributes: {
          "Integry Account ID": account_id,
          "Integry Account Name": account_name,
          "First Name": first_name,
          "Last Name": last_name,
          "Company Size": company_size,
          "Company Link": company_link,
          "Platform Type": platform_type,
          Role: role,
          onboardingInfo,
        },
      });
    }
  }
  useEffect(() => {
    const { pathname = "" } = location;
    if (pathname === "") {
      document.title = `Dashboard - Integry`;
    } else {
      const splittedPath = pathname.split("/");
      if (splittedPath[1]) {
        switch (splittedPath[1]) {
          case "apps":
            document.title = `Connectors - Integry`;
            break;
          case "deployments":
            document.title = `Marketplace - Integry`;
            break;
          case "templates":
          case "flows":
            document.title = `Flows - Integry`;
            break;
        }
      }
    }
  }, [location.pathname]);
  return (
    <>
      {show404Page ? (
        <NoMatchFound />
      ) : (
        <Suspense fallback={<IntegryLoader />}>
          <Switch>
            <Route
              path="/"
              exact
              render={(routeProps) => (
                <Redirect to={Urls.homePage} {...routeProps} />
              )}
            />

            <Route
              path="/templates/"
              exact
              render={(routeProps) => (
                <Redirect to={Urls.templateUrlFlagged} {...routeProps} />
              )}
            />
            <Route
              path="/book-a-call/"
              exact
              render={(routeProps) => (
                <DashboardWrapper user={user} className="" alertBar>
                  <CalendlyWidget
                    user={user}
                    title={lang.SCHEDULE_CALL_TITLE}
                    description={lang.CALENDLY_SUB_CAPTION}
                    stretchWithinEle
                    customStyle={{
                      paddingTop: "63px",
                    }}
                    sourcePage="Book a call"
                  />
                </DashboardWrapper>
              )}
            />
            <Route
              path="/apps/v4/"
              render={(routeProps) => (
                <FeatureFlag
                  flagKey="multiplayer-v2-connectors"
                  renderFeatureCallback={() => (
                    <AppsV4 {...props} {...routeProps} appLockFunc={false} />
                  )}
                  renderDefaultCallback={() => (
                    <AppsV4 {...props} {...routeProps} />
                  )}
                />
              )}
            />
            <Route
              path="/apps/v3/"
              render={(routeProps) => <AppsV3 {...props} {...routeProps} />}
            />
            <Route
              path="/apps/"
              render={(routeProps) => <Apps {...props} {...routeProps} />}
            />
            <Route
              path="/external-apps/"
              render={(routeProps) => <Apps {...props} {...routeProps} />}
            />

            <Route
              path="/deployments/v1/"
              render={(routeProps) => <Bundles {...props} {...routeProps} />}
            />

            <Route
              path="/deployments/v2/"
              render={(routeProps) => <BundlesV2 {...props} {...routeProps} />}
            />

            <Route
              path="/deployments/v3/"
              render={(routeProps) => <BundlesV3 {...props} {...routeProps} />}
            />

            <Route
              path="/templates/v1/"
              render={(routeProps) => <Templates {...props} {...routeProps} />}
            />

            <Route
              path="/templates/v2/"
              render={(routeProps) => (
                <TemplatesV2 {...props} {...routeProps} />
              )}
            />
            <Route
              path="/templates/v3/"
              render={(routeProps) => (
                <TemplatesV3 {...props} {...routeProps} />
              )}
            />
            <Route
              path="/templates/v4/"
              render={(routeProps) => (
                <TemplatesV4 {...props} {...routeProps} />
              )}
            />
            <Route
              path="/templates/v5/"
              render={(routeProps) => (
                <TemplatesV5 unifiedTesting {...props} {...routeProps} />
              )}
            />
            <Route
              path="/templates/v6/"
              render={(routeProps) => (
                <TemplatesV6 unifiedTesting {...props} {...routeProps} />
              )}
            />

            <Route
              path="/flow/:flowId/integration/:intgId/runs/:runId/tasks/:taskId/details"
              render={(routeProps) => (
                <EndUserAnalytic {...props} routeProps={routeProps} />
              )}
            />
            <Route
              path="/flow/:flowId/integration/:intgId/runs/:runId/tasks"
              render={(routeProps) => (
                <EndUserAnalytic {...props} routeProps={routeProps} />
              )}
            />
            <Route
              path="/flow/:flowId/integration/:intgId/runs/"
              render={(routeProps) => (
                <EndUserAnalytic {...props} routeProps={routeProps} />
              )}
            />
            <Route
              path={Urls.embedUrl}
              render={(routeProps) => (
                <FeatureFlag
                  flagKey="embed--allow-viewstyle-toggle"
                  renderDefaultCallback={() => (
                    <EmbedPreview user={props.user} {...routeProps} />
                  )}
                  renderFeatureCallback={() => (
                    <EmbedPreview
                      user={props.user}
                      {...routeProps}
                      isBeta={true}
                    />
                  )}
                />
              )}
            />
            <Route
              path={Urls.embedBeta}
              render={(routeProps) => (
                <FeatureFlag
                  flagKey="embed--allow-viewstyle-toggle"
                  renderDefaultCallback={() => (
                    <EmbedPreview
                      user={props.user}
                      {...routeProps}
                      enableBeta
                    />
                  )}
                  renderFeatureCallback={() => (
                    <EmbedPreview
                      user={props.user}
                      {...routeProps}
                      isBeta={true}
                      enableBeta
                    />
                  )}
                />
              )}
            />
            <Route
              path="/flows/"
              render={(routeProps) => (
                <TemplatesV7 unifiedTesting {...props} {...routeProps} />
              )}
            />
            <Route // This route is Editing/creating the flow
              path="/flow/"
              render={(routeProps) => (
                <TemplatesV7 unifiedTesting {...props} {...routeProps} />
              )}
            />
            <Route
              path="/integration-view/flow/:flowId/integration/:intgId/run/:runId/steps"
              render={(routeProps) => (
                <FeatureFlag
                  flagKey="integration-view-v1"
                  renderFeatureCallback={() => (
                    <IntegrationViewWrapper
                      integrationViewType={
                        integrationViewTypeEnum.INTEGRATION_RUN_STEPS
                      }
                      {...props}
                      routeProps={routeProps}
                      user={userObj}
                    />
                  )}
                  renderDefaultCallback={() => <></>}
                />
              )}
            />
            <Route
              path="/integration-view/flow/:flowId/integration/:intgId/runs/"
              render={(routeProps) => (
                <FeatureFlag
                  flagKey="integration-view-v1"
                  renderFeatureCallback={() => (
                    <IntegrationViewWrapper
                      integrationViewType={
                        integrationViewTypeEnum.INTEGRATION_RUNS
                      }
                      {...props}
                      routeProps={routeProps}
                      user={userObj}
                    />
                  )}
                  renderDefaultCallback={() => <></>}
                />
              )}
            />
            <Route
              path="/integration-view/flow/:flowId/integrations/"
              render={(routeProps) => (
                <FeatureFlag
                  flagKey="integration-view-v1"
                  renderFeatureCallback={() => (
                    <IntegrationViewWrapper
                      integrationViewType={
                        integrationViewTypeEnum.ALL_INTEGRATIONS
                      }
                      {...props}
                      routeProps={routeProps}
                      user={userObj}
                    />
                  )}
                  renderDefaultCallback={() => <></>}
                />
              )}
            />
            <Route
              path="/view/flow/"
              render={(routerProps) => (
                <FeatureFlag
                  flagKey="integration-view-v1"
                  renderFeatureCallback={() => (
                    <Integrations user={user} {...routerProps} />
                  )}
                  renderDefaultCallback={() => <NoMatchFound />}
                />
              )}
            />
            <Route
              path={Urls.endUsers}
              render={(routerProps) => (
                <EndUsers user={user} {...routerProps} />
              )}
            />
            <Route
              path={Urls.users}
              render={(routerProps) => <Users user={user} {...routerProps} />}
            />
            <Route
              path={Urls.runTasksV0}
              render={(routeProps) => (
                <Redirect
                  to={Urls.runTasks
                    .replace(
                      ":integrationId",
                      routeProps?.match?.params?.integrationId
                    )
                    .replace(":runId", routeProps?.match?.params?.runId)}
                />
              )}
            />
            <Route
              path={Urls.runTasks}
              render={(routeProps) => (
                <>
                  <PageTitle title="Integration Runs - Integry" />
                  <IntegrationRunsV2
                    showBillableRunStatus={true}
                    flowConfig={{}} // TODO: Clean this up
                    setFlowConfig={() => ({})}
                    setIntegrationConfig={() => ({})}
                    setAppConfig={() => ({})}
                    setAppUserConfig={() => ({})}
                    integrationIdParam={
                      routeProps?.match?.params?.integrationId
                    }
                    runIdParam={routeProps?.match?.params?.runId}
                  />
                </>
              )}
            />
            <Route
              path={Urls.integrationPolls}
              render={(routeProps) => (
                <>
                  <PageTitle title="Integration Runs - Integry" />
                  <IntegrationRunsV2
                    showBillableRunStatus={true}
                    flowConfig={{}} // TODO: Clean this up
                    setFlowConfig={() => ({})}
                    setIntegrationConfig={() => ({})}
                    setAppConfig={() => ({})}
                    setAppUserConfig={() => ({})}
                    integrationIdParam={
                      routeProps?.match?.params?.integrationId
                    }
                    defaultTab="Polls"
                  />
                </>
              )}
            />
            <Route
              path={Urls.integrationRuns}
              render={(routeProps) => (
                <>
                  <PageTitle title="Integration Runs - Integry" />
                  <IntegrationRunsV2
                    showBillableRunStatus={true}
                    flowConfig={{}} // TODO: Clean this up
                    setFlowConfig={() => ({})}
                    setIntegrationConfig={() => ({})}
                    setAppConfig={() => ({})}
                    setAppUserConfig={() => ({})}
                    integrationIdParam={
                      routeProps?.match?.params?.integrationId
                    }
                    defaultTab="Runs"
                  />
                </>
              )}
            />
            <Route
              path={Urls.integrations}
              render={() => (
                <>
                  <PageTitle title="Integrations - Integry" />
                  <IntegrationsV2
                    showBillableRunStatus={true}
                    endUserId={1925116}
                    appId={491}
                    flowId={31362}
                    flowConfig={{}}
                    setFlowConfig={() => ({})}
                    setIntegrationConfig={() => ({})}
                    setAppConfig={() => ({})}
                    setAppUserConfig={() => ({})}
                  />
                </>
              )}
            />

            <Route
              path="/dashboard"
              render={(routeProps) => (
                <FeatureFlag
                  flagKey="onboarding-by-website"
                  renderDefaultCallback={() => (
                    <CanduDashboard {...props} {...routeProps} />
                  )}
                  renderFeatureCallback={() => (
                    <Redirect to={Urls.templateUrlV6} {...routeProps} />
                  )}
                />
              )}
            />
            <Route
              path="/insights/"
              render={(routeProps) => <Insights {...props} {...routeProps} />}
            />

            <Route
              path="/settings/"
              render={(routeProps) => <Settings {...props} {...routeProps} />}
            />

            <Route
              path="/marketplace/v2/"
              render={(routeProps) => (
                <>
                  <PageTitle title="Marketplace - Integry" />
                  <Marketplace {...props} {...routeProps} />
                </>
              )}
            />

            <Route
              path="/marketplace/v3/"
              render={(routeProps) => (
                <>
                  <PageTitle title="Marketplace - Integry" />
                  <MarketplaceV3 {...props} {...routeProps} />
                </>
              )}
            />

            <Route
              path="/marketplace/:marketplaceId/app/:bundleAppId"
              render={(routeProps) => <AppPage {...props} {...routeProps} />}
            />

            <Route
              path="/marketplace/:marketplaceId/settings"
              render={(routeProps) => (
                <MarketPlaceSettings {...props} {...routeProps} />
              )}
            />

            <Route
              path="/marketplace/embed/sdk-preview"
              exact
              render={(routeProps) => (
                <ConnectedSdkPreview {...props} {...routeProps} />
              )}
            />

            <Route
              path="/marketplace/:bundleId"
              exact
              render={(routeProps) => (
                <MarketplaceV2 {...props} {...routeProps} />
              )}
            />

            <Route
              path="/marketplace/"
              exact
              render={(routeProps) => (
                <MarketplaceV2 {...props} {...routeProps} />
              )}
            />

            {user.account_id !== config.adminAccountId && (
              <Route
                path="/actions/"
                render={(routeProps) => (
                  <ActionsTurbo {...props} {...routeProps} />
                )}
              />
            )}
            <Route path={urls.notFound} component={NoMatchFound} />
            <Route component={NoMatchFound} />
          </Switch>
        </Suspense>
      )}
    </>
  );
};

const Dashboard = (props) => {
  const history = useHistory();
  const { location, deploymentCardLoader, onboardingState, user } = props;
  const [showWebsiteOnboardinSuccess, setShowWebsiteOnboardinSuccess] =
    useState(false);
  const [showTrialBanner, setShowTrialBanner] = useState(false);
  const [showTrialPopup, setShowTrialPopup] = useState(
    false
    /*! user.onboarding_completed && user.trial_days_left <= 3 */
  );

  if (showTrialPopup) {
    if (localStorage.getItem("trial_popup")) setShowTrialPopup(false);
  }

  const { pathname } = location;
  const { length } = pathname;

  useEffect(() => {
    if (
      !onboardingState?.isFetching &&
      !onboardingState?.hideWebsiteOnboardingSuccessPortal
    ) {
      setShowWebsiteOnboardinSuccess(true);
    }
    // setShowTrialBanner(true);
    try {
      fetchAndSetSystemGeneratedObjects({
        pageSize: "30",
      });
    } catch (e) {
      console.error("ERROR IN FETCHING SYSTEM GENERATED OBJECTS", { e });
    }
  }, [onboardingState?.isFetching]);

  const liteTemplateCheck = pathname
    .substring(length - 5, length)
    .includes("lite");

  const isUnfinishedWork = () => {
    if (deploymentCardLoader) {
      return true;
    }
    return false;
  };

  return (
    <>
      {showTrialBanner &&
        user.trial_days_left > 0 &&
        user.email.includes("@integery.com") && (
          <TrialPeriodBanner remainingDays={user.trial_days_left} />
        )}
      {showTrialPopup &&
        user.email.includes("@integery.com") &&
        !location.pathname.includes("/book-a-call") && (
          <RemainingTrialDaysPopup
            remainingDays={user.trial_days_left}
            onClose={() => {
              if (user.trial_days_left > 0) {
                localStorage.setItem("trial_popup", true);
                setShowTrialPopup(false);
              }
              // history.push("/apps/v4/book-a-call");
              window.open("/wapp/book-a-call", "_blank");
            }}
            onlyClose={() => {
              if (user.trial_days_left > 0) {
                localStorage.setItem("trial_popup", true);
                setShowTrialPopup(false);
              }
            }}
          />
        )}

      {isUnfinishedWork() && (
        <Beforeunload onBeforeunload={() => "You’ll lose your data!"} />
      )}
      {liteTemplateCheck ? (
        <MainRouting {...props} hasIntercom={false} />
      ) : (
        <IntercomProvider appId={config.intercomAppId}>
          <WorkspaceAppProvider>
            <FeatureFlagsProvider>
              <MainRouting {...props} hasIntercom />
            </FeatureFlagsProvider>
          </WorkspaceAppProvider>
          {/* {showWebsiteOnboardinSuccess &&
            user.date_joined > 1669978327 && ( // show to the users who joined after 2-Dec-2022 i.e after relase date of Website onboarding integry-turbo-signup
              <FeatureFlag
                flagKey="integry-turbo-signup"
                renderFeatureCallback={() => (
                  <IntegryTurboOnboardingPortal
                    onClose={() => {
                      props.updateOnboardingState({
                        hideWebsiteOnboardingSuccessPortal: true,
                      });
                      setShowWebsiteOnboardinSuccess(false);
                    }}
                  />
                )}
                renderDefaultCallback={() => (
                  <WebsiteOnboardingSuccessPortal
                    onClose={() => {
                      props.updateOnboardingState({
                        hideWebsiteOnboardingSuccessPortal: true,
                      });
                      setShowWebsiteOnboardinSuccess(false);
                    }}
                  />
                )}
              />
            )} */}
        </IntercomProvider>
      )}
    </>
  );
};

const IntercomFlaggedDashboard = (props) => (
  <FeatureFlag
    flagKey="intercom"
    renderFeatureCallback={() => (
      <FeatureFlag
        flagKey="flows-rebranding"
        renderFeatureCallback={() => (
          <Dashboard {...props} shouldInitializeIntercom rebrandedFlows />
        )}
        renderDefaultCallback={() => (
          <Dashboard {...props} shouldInitializeIntercom />
        )}
      />
    )}
    renderDefaultCallback={() => (
      <FeatureFlag
        flagKey="flows-rebranding"
        renderFeatureCallback={() => <Dashboard {...props} rebrandedFlows />}
        renderDefaultCallback={() => <Dashboard {...props} />}
      />
    )}
  />
);

export default connect(
  (state) => ({
    onboardingState: state?.onboardingState,
    deploymentCardLoader: state?.bundles?.deploymentCardLoader,
    show404Page: state?.toggle404Page?.show404Page,
  }),
  (dispatch) => ({
    updateOnboardingState(state) {
      dispatch(updateOnboardingState(state));
    },
  })
)(IntercomFlaggedDashboard);
