import scheduleIcon from "images/templates-v3/schedule-icon.svg";
import incomingWebhookIcon from "images/incoming-webhook-icon.svg";
import notificationAppIcon from "images/notification/notification-app.png";
import externalActionAppIcon from "images/templates-v6/external-action.svg";
import formAppIcon from "images/flow-steps/form-app-icon.png";
import httpCallIcon from "images/http_call_avatar.svg";
import loopIcon from "images/loop-emblem-2.svg";

const {
  REACT_APP_LAUNCHDARKLY_CLIENT_ID,
  REACT_APP_INTERCOM_APP_ID,
  REACT_APP_INSIGHTS_API_KEY,
  REACT_APP_FULLSTORY_ORG_ID,
  // REACT_APP_USERFLOW_ENVIRONMENT_TOKEN,
  REACT_APP_MICROSERVICES_BASE_URL,
  REACT_APP_API_BASE_URL,
  REACT_APP_CONTENTFUL_SPACE,
  REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  REACT_APP_NEW_USER_SMARTBOX_DATE_TARGET,
  REACT_APP_NOTIFICAION_SDK_APP_KEY,
  REACT_APP_NOTIFICAION_SDK_APP_SECRET,
} = process.env;

const microservicesBaseUrl = REACT_APP_MICROSERVICES_BASE_URL;
const apiBaseURL = REACT_APP_API_BASE_URL;
const isLocal = process.env.NODE_ENV !== "production";
const contentful = {
  space: REACT_APP_CONTENTFUL_SPACE || "1",
  accessToken: REACT_APP_CONTENTFUL_ACCESS_TOKEN || "-1",
};
const smartboxNewUserTargetDate =
  REACT_APP_NEW_USER_SMARTBOX_DATE_TARGET ||
  "2021-11-15"; /** THE DATE VALUE TO CHECK DISTINGUISH BETWEEN THE NEW USER FOR ONBOARDING V2 WORKFLOW, */
const config = {
  apiBaseURL,
  mixpanelEndpoint: "/mixpanel/track/",
  conditions: {
    appId: 228,
    ifActivity: {
      id: 540,
      conditionJsonField: {
        id: 1739,
        machine_name: "condition_json",
      },
      activityType: "CONDITION_IF",
    },
    elseIfActivity: {
      id: 541,
      conditionJsonField: {
        id: 1740,
        machine_name: "condition_json",
      },
      activityType: "CONDITION_ELSEIF",
    },
    elseActivity: {
      id: 542,
      activityType: "CONDITION_ELSE",
    },
  },
  setup: {
    appId: 114,
    activityId: 874,
  },
  loop: {
    name: "For Each Loop",
    appId: 311,
    activityId: 1005,
    activityType: "LOOP",

    arrayField: {
      id: 2986,
      name: "Array",
      machine_name: "input_array",
      description: "The array that will be looped",
    },
    variableField: {
      id: 2987,
      name: "Variable Name",
      machine_name: "current_item_variable_name",
      description: "Enter some variable name that can be used in later steps",
    },
    maxEecutionTimeField: {
      id: 154747,
      name: "Max. execution time (minutes)",
      machine_name: "max_execution_time_minutes",
      description: "Max. execution time in minutes (must be 48 hours or less)",
      default_value: "60",
    },
  },

  doWhileLoop: {
    appId: 27625,
    activityId: 37356,
    icon: loopIcon,
    activityType: "DO_WHILE_LOOP",
    dummyStepType: "DUMMY_DO_WHILE_LOOP",
    name: "Do While Loop",
    activity: {
      id: 37356,
      type: "DO_WHILE_LOOP",
      fields: [
        {
          id: 153741,
          name: "Condition",
          machine_name: "condition_json",
          default_value: "",
        },
        {
          id: 154693,
          name: "Maximum execution time (minutes)",
          machine_name: "max_execution_time_minutes",
          description:
            "The loop will automatically stop once this time is reached.",
          default_value: "60",
        },
      ],
    },
  },

  schedule: {
    appId: 338,
    activityId: 1374,
    activityVersion: "2",
    label: "Scheduled",
    icon: scheduleIcon,
    day: {
      id: 8254,
      name: "Day of Month",
      description: "Day of the month on which to run scheduled task (1-31)",
    },
    days: {
      id: 8255,
      name: "Days of Week",
      description:
        'JSON array of days of the week (["Monday", "Friday"]) (runs on these days)',
    },
    hours: {
      id: 8256,
      name: "Hour",
      description: "Hour of the Day on which to run scheduled task (00-23)",
    },
    minutes: {
      id: 8257,
      name: "Minutes",
      description: "Minute of the hour on which to run scheduled task (00-59)",
    },
    monthOfYear: {
      id: 3820,
      name: "Month Of Year",
      description:
        "* = any (default), 1-12 (once a year) or comma separated numbers (0,1,4) (runs on these months)",
    },
    dayOfMonth: {
      id: 3810,
      name: "Day of Month (cron)",
      description:
        "* = any (default), 1-31 (once a month) or comma separated numbers (1,3,10,20) (runs on these dates)",
    },
    dayOfWeek: {
      id: 3811,
      name: "Day of Week (cron)",
      description:
        "* = any (default), 0-6 (once a week) or comma separated numbers (0,2,5) (runs on these days)",
    },
    runHour: {
      id: 3812,
      name: "Run Hour (cron)",
      description:
        "* = any (default), 0-23 (once a day) or comma separated numbers (0,2,5,14) (runs on these hours)",
    },
    runMinute: {
      id: 3813,
      name: "Run Minute (cron)",
      description:
        "* = any (default), 0-59 (once an hour) or comma separated numbers (0,10,20,30,40,50) (runs on these minutes)",
    },
    offset_min: {
      id: 8392,
      name: "Offset in minutes (text)",
      description: "Offset in minutes. This field does not affect cron fields",
    },
    timezone: {
      id: 8403,
      name: "Timezone (text)",
      description: "E.g. Europe/London",
      machine_name: "timezone",
    },
    json: {
      id: 8404,
      name: "JSON",
      description: "The above fields can be entered using a JSON as well",
    },
    intervalType: {
      id: 181346,
      name: "Interval Type",
      machine_name: "interval_type",
    },
    intervalValue: {
      id: 181347,
      name: "Interval Value",
      machine_name: "interval_value",
    },
    cornExpression: {
      id: 181345,
      name: "Corn Expression",
      machine_name: "cron_expression",
    },
  },
  filter: {
    appId: 346,
  },
  storage: {
    appId: 113,
    set_value: {
      id: 148,
    },
    get_value: {
      id: 149,
    },
    increment_value: {
      id: 34462,
    },
    decrement_value: {
      id: 41585,
    },
    append_value: {
      id: 34503,
    },
    await_get_value: {
      id: 1980,
    },
    delete_key: {
      id: 1245,
    },
  },
  csvApp: {
    appId: 295,
  },
  jsonApp: {
    appId: 313,
  },
  fileApp: {
    appId: 296,
  },
  memoryApp: {
    appId: 315,
  },

  variable: {
    appId: 537,
    varActivity: {
      id: 3275,
      type: "VARIABLE",
      varActivityField: {
        id: 9251,
      },
    },
  },

  code: {
    appId: 669,
    activityId: 3480,
    label: "Custom Code",
    codeField: {
      id: 9691,
      name: "Code",
    },
    outputField: {
      id: 152552,
      name: "Output",
      machine_name: "output",
    },
  },

  makeHttpCall: {
    appId: 12408,
    activityId: 21584,
    endpointId: 107139,
    label: "HTTP Call",
    icon: httpCallIcon,
    httpVerb: {
      id: 60157,
      default_value: "get",
    },
    url: {
      id: 60158,
    },
    headers: {
      id: 60159,
    },
    body: {
      id: 60160,
    },
    parameters: {
      id: 60161,
    },
    output: {
      id: 116460,
      machine_name: "output",
    },
  },
  incomingWebhook: {
    appId: 14296,
    activityId: 22030,
    label: "Incoming Webhook",
    icon: incomingWebhookIcon,
    // system step fields
    app: {
      id: 61393,
    },
    will_receive: {
      id: 61394,
    },
    userId: {
      id: 61395,
    },
    url: {
      id: 61396,
    },
    filters: {
      id: 61397,
    },
    userExperience: {
      id: 61398,
    },
    integry_webhook_object: {
      id: 107937,
    },
    payload_name: {
      id: 183021,
    },
  },

  webhookActivity: {
    appId: 217,
    activityId: 4078,
  },

  notification: {
    appId: 22978,
    activityId: 30860,
    appIcon: notificationAppIcon,
    label: "Notification",
    instance_id: {
      id: 101394,
    },
    webhook_url: {
      id: 101395,
    },
    action_id: {
      id: 101396,
    },
    instance_app: {
      id: 101397,
    },
    instance_name: {
      id: 101398,
    },
  },

  external_action: {
    appId: 23628,
    activityId: 31665,
    activityType: "EXTERNAL_ACTION",
    label: "External Action",
    appIcon: externalActionAppIcon,
    instance_id: {
      id: 110642,
    },
    webhook_url: {
      id: 110643,
    },
    action_id: {
      id: 110644,
    },
    instance_app: {
      id: 110645,
    },
    instance_name: {
      id: 110646,
    },
    action_output: {
      id: 110794,
    },
  },
  form_block: {
    appId: 24185,
    activityId: 32515,
    appIcon: formAppIcon,
  },

  abortStep: {
    activityId: 31608,
  },

  returnStep: {
    activityId: 43885,
  },

  // Microservice based activities don't have endpoints. So they can't natively specify
  // what object and what action they support.
  // We maintain this list here and merge it with the appropriate activity in
  // fetchActivities redux action
  microservicesOutputObjects: {
    // Mailchimp
    25: {
      // TRIGGER: New Subscriber in List
      39: { object: 1, endpoint_action: "CREATE" },
      // TRIGGER: Subscriber Updated in List
      352: { object: 1, endpoint_action: "UPDATE" },
      // ACTION: Add New Subscriber to List
      38: { object: 1, endpoint_action: "CREATE" },
      // ACTION: Update Subscriber in List
      353: { object: 1, endpoint_action: "UPDATE" },
    },
  },
  microservicesBaseUrl,
  endpointsServiceId: 22,
  endointsCallBaseUrl: `${microservicesBaseUrl}endpoints/call/`,
  useHttpBasicAuth: process.env.NODE_ENV !== "production",
  httpAuth: {
    username: "",
    password: "",
  },
  getLoginUrl() {
    return `${apiBaseURL}/accounts/login`;
  },
  getLogoutUrl() {
    return `${apiBaseURL}/accounts/logout`;
  },
  getNotFoundPageUrl() {
    return `${apiBaseURL}/wapp/404/`;
  },

  intercomAppId: REACT_APP_INTERCOM_APP_ID,
  launchDarklyClientId: REACT_APP_LAUNCHDARKLY_CLIENT_ID,
  insightsAPIKey: REACT_APP_INSIGHTS_API_KEY,
  fullStoryRoute: "app.integry.io/fsrelay",
  fullStoryScriptRoute: "app.integry.io/fsrelay/s/fs.js",
  fullStoryOrgId: REACT_APP_FULLSTORY_ORG_ID,
  // userflowEnvironmentToken: REACT_APP_USERFLOW_ENVIRONMENT_TOKEN,
  numberOfCardsOptions: [
    { value: 3, id: 3 },
    { value: 4, id: 4 },
    { value: 5, id: 5 },
  ],
  positionOptions: [
    { id: "LEFT", value: "Left" },
    { id: "TOP", value: "Top" },
    { id: "RIGHT", value: "Right" },
  ],
  positionOptions2: [
    { id: "LEFT", value: "Left (as a list)" },
    { id: "TOP-RIGHT", value: "Top-right (as a drop-down)" },
  ],
  linksPositionOptions: [
    { id: "LEFT", value: "Left" },
    { id: "RIGHT", value: "Right" },
  ],
  categoryNumberOfCardsOptions: [
    { value: 1, id: 1 },
    { value: 2, id: 2 },
    { value: 3, id: 3 },
    { value: 4, id: 4 },
    { value: 5, id: 5 },
    { value: 6, id: 6 },
    { value: 7, id: 7 },
    { value: 8, id: 8 },
    { value: 9, id: 9 },
    { value: 10, id: 10 },
  ],
  categorySortingOptions: [
    { id: "ALPHABETIC", value: "Alphabetic" },
    { id: "NEWEST_FIRST", value: "Newest First" },
    { id: "MANUAL", value: "Manual" },
  ],
  jsonTypeOptions: [
    { id: "STRING", value: "String" },
    { id: "NUMBER", value: "Number" },
    { id: "BOOLEAN", value: "Boolean" },
  ],
  customActivity: {
    urlActivityField: {
      activity_field_machine_name: "custom_activity_url",
      auto_machine_name: "",
      child_fields: "",
      data_conversion: null,
      data_src: "",
      data_src_endpoint: null,
      default_value: null,
      description:
        "Endpoint URL can be provided here to replace the existing default URL for test",
      dynamic_field_src: "",
      dynamic_field_src_endpoint: null,
      id: "custom_activity_url",
      is_dynamic: false,
      is_required: false,
      is_searchable: false,
      machine_name: "custom_activity_url",
      object_field: "None",
      parent_fields: "",
      regex: "",
      title: "Endpoint URL",
      type: "TEXTFIELD",
      weight: 1,
    },
    requestBodyActivityField: {
      activity_field_machine_name: "custom_request_body",
      auto_machine_name: "",
      child_fields: "",
      data_conversion: null,
      data_src: "",
      data_src_endpoint: null,
      default_value: null,
      description: "Request body for the test can be provided here",
      dynamic_field_src: "",
      dynamic_field_src_endpoint: null,
      id: "custom_request_body",
      is_dynamic: false,
      is_required: false,
      is_searchable: false,
      machine_name: "custom_request_body",
      object_field: "None",
      parent_fields: "",
      regex: "",
      title: "Request body",
      type: "TEXTAREA",
      weight: 1,
    },
    headerActivityField: {
      activity_field_machine_name: "Header",
      auto_machine_name: "header",
      child_fields: "",
      data_conversion: null,
      data_src: "",
      data_src_endpoint: null,
      default_value: null,
      description: "",
      dynamic_field_src: "",
      dynamic_field_src_endpoint: null,
      id: "Header",
      is_dynamic: false,
      is_required: false,
      is_searchable: false,
      machine_name: "Header",
      object_field: "None",
      parent_fields: "",
      regex: "",
      title: "Request body",
      type: "TEXTFIELD",
      weight: 1,
    },
  },
  appIdsForDisabledCustomActivity: [491],
  contentful,
  smartboxNewUserTargetDate,
  dummyTag: false,
  // calendlyMeetingLink: `https://calendly.com/usman-integry/30min`,
  calendlyMeetingLink: `https://calendly.com/integry-acquisition-team/onboarding`,
  totangoIntegryWebhook: `https://us-central1-integry-app.cloudfunctions.net/webhook_entry/call/08129397-d0d0-4613-b5f2-3a0d323ead66/int/88b79c33-5585-4f2a-97c0-09fa2d51417f/step/b7a03330-8c8b-49e4-acf0-c38c92a36709`,
  errorNotificationCreds: {
    deploymentId: apiBaseURL?.includes("beta") ? "4580" : "5856",
    appKey: apiBaseURL?.includes("beta")
      ? "8514181a-bca0-487e-ae2d-07688e40ec6c"
      : "9793f445-40c3-4ac4-aa4e-a285e217778e",
  },
  systemAppIds: [217, 24185],
  adminAccountId: 2,
  notificationSDKAppKey: REACT_APP_NOTIFICAION_SDK_APP_KEY,
  conditionalOptions: {
    CONTAINS: "contains",
    STARTS_WITH: "starts with",
    ENDS_WITH: "ends with",
    DOES_NOT_CONTAIN: "does not contain",
    DOES_NOT_START_WITH: "does not start with",
    DOES_NOT_END_WITH: "does not end with",
    "=": "equals",
    "<>": "does not equal",
    ">": "greater than",
    "<": "less than",
    IS_TRUE: "is true",
    IS_NOT_TRUE: "is false",
    IS_PRESENT: "is present",
    IS_NOT_PRESENT: "is not present",
    IS_EMPTY: "is empty",
    IS_NOT_EMPTY: "is not empty",
    IS_NULL: "is null",
    IS_NOT_NULL: "is not null",
  },
};

export default config;
