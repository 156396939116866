import React, { Component, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import onClickOutside from "react-onclickoutside";

import CancelButton from "../../../../images/cancel.svg";

class CommonTasksElipsis extends Component {
  state = {
    showStepApps: false,
  };

  toggleStepApps = (showStepApps) => {
    const { toggleElipsis } = this.props;
    toggleElipsis(false);
    this.setState({ showStepApps: showStepApps });
  };

  handleClickOutside = (evt) => {
    const { showElipsis } = this.props;
    const { showStepApps } = this.state;
    if (showElipsis || showStepApps) {
      this.toggleStepApps(false);
    }
  };

  render() {
    const {
      showElipsis,
      step_guid,
      apps = [],
      toggleElipsis,
      icon_url,
    } = this.props;
    const { showStepApps } = this.state;
    return (
      <Fragment>
        {showElipsis && (
          <div className={"elipsis-menu"}>
            <CopyToClipboard
              text={step_guid}
              onCopy={() => toggleElipsis(false)}
            >
              <div className={"items align-center"}>Copy GUID</div>
            </CopyToClipboard>
            <div className={"middle-section"}></div>
            <div
              onClick={() => this.toggleStepApps(true)}
              className={"items align-center"}
            >
              View App Detail
            </div>
          </div>
        )}

        {showStepApps && (
          <div className={"run-apps-details"}>
            <img
              onClick={() => this.toggleStepApps(false)}
              src={CancelButton}
              alt="Cancel"
              className="close-btn"
            />
            <div className={"run-apps-heading"}>
              <div className={"items align-center"}></div>
              <div className={"items align-center"}></div>
              <div className={"items align-center"}>APP</div>
              <div className={"items align-center"}>ID</div>
            </div>

            {apps.map((app, index) => (
              <div className="apps-row-container  align-center" key={app.id}>
                <div>{index + 1}</div>
                <img
                  src={app.icon_url || icon_url}
                  alt=""
                  height={app.icon_url ? 20 : 15}
                  width={app.icon_url ? 20 : 15}
                  style={app.icon_url ? {} : { borderRadius: 0 }}
                />
                <div>{app.name}</div>
                <div>{app.id}</div>
              </div>
            ))}
          </div>
        )}
      </Fragment>
    );
  }
}

export default onClickOutside(CommonTasksElipsis);
