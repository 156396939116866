/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useRef, useState } from "react";
import { IntegryJS } from "@integry/sdk";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FeatureFlag } from "react-launch-darkly";

import config from "utils/config";
import { getMyAppById } from "selectors/apps";
import { ErrorNotificationInterface } from "features/account-settings/services/interface";
import { App } from "legacy-features/templates/v5/services/template-builder-interface";
import IntegryLoader from "components/integry-loader";
import HelpText from "components/integry-design-system/molecules/help-text/help-text";
import NotificationUrl from "components/integry-design-system/molecules/error-notifcation-url/notification-url";
import NotificationThrottle from "components/integry-design-system/molecules/error-notifcation-throttle/notification-throttle";
import { User } from "types/integry-user";
import PopupContainer from "components/integry-design-system/molecules/popup-container/popup-container";
import allDoneIcon from "images/all-done-popup-icon.svg";
import { track, errorNotificationEvents } from "utils/mixpanel";
import {
  useGetNotificationSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from "utils/accounts-api";

interface IProps {
  user: User;
  webhookURLSampleNotification?: boolean;
}

const ErrorNotifications = ({
  user,
  webhookURLSampleNotification,
}: IProps): ReactElement => {
  const [showNotificationCreatedSuccess, setShowNotificationCreatedSuccess] =
    useState(false);
  const [integrationName, setIntegrationName] = useState("");
  const [saveType, setSaveType] = useState("");
  const [notificationsMuted, setNotificationsMuted] = useState(false);
  const [isSavingUrl, setSavingUrl] = useState(false);

  const initializeSDK = useRef(true);

  const { data: notificationSettings, isLoading } =
    useGetNotificationSettingsQuery();
  const [updateNotificationSettings, updateNotificationResult] =
    useUpdateNotificationSettingsMutation();

  const accountId = `${user.account_id}`;

  useEffect(() => {
    let IntegryJs: IntegryJS;
    const initSDK = async (): Promise<null> => {
      const { appKey, deploymentId } = config.errorNotificationCreds;

      IntegryJs = new IntegryJS({
        appKey,
        userId: accountId, // using Account id instead of user id to make integration accessable for invited users
        deploymentId,
        hash: notificationSettings?.hash || "",
        env: config.apiBaseURL?.includes("beta") ? "staging" : "production",
        userConfig: {
          availableFlowsLabel: "Notifications",
          myFlowsLabel: "Manage",
          hideWebhookUrlScreen: true,
          autoRedirectToMyFlows: true,
        },
        xIntegryConfig: {
          appAuth: {
            apiKey: user.partner_api_key || "-1",
            extras: {},
          },
        },
        options: {
          tags: ["error-notification"],
          availableFlowsLabel: "Notifications",
        },
      });
      IntegryJs.init({
        containerId: "sdk-preview-container",
        // renderMode: IntegryJS.RenderModes.INLINE,
        // renderFlowsMode: IntegryJS.RenderModes.MODAL,
        renderMode: IntegryJS.RenderModes.INLINE,
        renderFlowsMode: IntegryJS.RenderModes.INLINE,
        // showApps: true,
        showTemplates: true,
        skipOnDemand: true,
        testMultipurpose: false,
      });

      IntegryJs.printVersion();
      IntegryJs.eventEmitter.on("did-load-flow", (event) => {
        trackErrorNotification(
          errorNotificationEvents.BEGIN_ERROR_NOTIFICATION_SETUP,
          event.flowTitle
        );
      });
      IntegryJs.eventEmitter.on("did-save-integration", (event) => {
        if (event.event === "CREATE") {
          const appName = event.name.split(" ").pop() || ""; // Last word of the template has is App name
          setIntegrationName(appName);
          setShowNotificationCreatedSuccess(true);
          trackErrorNotification(
            errorNotificationEvents.ERROR_NOTIFICATION_SETUP_COMPLETED,
            event.name
          );
        }
      });
      IntegryJs.eventEmitter.on("did-delete-integration", (event) => {
        trackErrorNotification(
          errorNotificationEvents.DELETE_NOTIFICATION_APP,
          `${event.flowName}`
        );
      });
      return null;
    };

    if (notificationSettings?.hash && initializeSDK.current) {
      initSDK();
      initializeSDK.current = false;
    }
    return () => {
      if (IntegryJs) {
        // console.log("filtered destroying SDK");
        IntegryJs.destroy();
      }
    };
  }, [notificationSettings?.hash]);

  useEffect(() => {
    if (updateNotificationResult.status === "fulfilled") {
      if (saveType === "notification-throttle" && notificationsMuted)
        toast.success(
          <p className="toast-text">
            Notifications have been muted successfully.
          </p>
        );
      else if (saveType === "notification-throttle" && !notificationsMuted)
        toast.success(
          <p className="toast-text">
            Notifications have been unmuted successfully.
          </p>
        );
      else {
        const { error_notification_url } = updateNotificationResult?.data;
        setSavingUrl(false);
        if (webhookURLSampleNotification && error_notification_url) {
          toast.success(
            <p className="toast-text">
              URL has been saved successfully
              <br />
              You will receive an example notification soon.
            </p>
          );
          // sendSampleNotificaion(error_notification_url);
        } else if (error_notification_url) {
          toast.success(
            <p className="toast-text">
              Notifications URL has been saved successfully.
            </p>
          );
        } else {
          // if url is removed
          toast.success(
            <p className="toast-text">
              Notifications URL has been removed successfully.
            </p>
          );
        }
      }
    } else if (updateNotificationResult.status === "rejected") {
      setSavingUrl(false);
      toast.error("Failed to Save Settings.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateNotificationResult.status]);

  const handleSaveNotificationSettings = (formValues): void => {
    setNotificationsMuted(formValues.is_error_throttling_enabled);
    updateNotificationSettings(formValues);
  };

  const trackErrorNotification = (
    eventObject: { name: string; property: string },
    propertyValue: string
  ): void => {
    track(eventObject.name, user, {
      [eventObject.property]: propertyValue,
    });
  };

  const onWebhookUrlSave = (values: ErrorNotificationInterface): void => {
    setSavingUrl(true);
    handleSaveNotificationSettings({
      ...notificationSettings,
      error_notification_url: values.error_notification_url,
    });
    setSaveType("notification-url");
    if (values.error_notification_url) {
      trackErrorNotification(
        errorNotificationEvents.SEND_NOTIFICATION_TO_URL,
        values.error_notification_url
      );
    }
  };

  const onSettingSave = (values: ErrorNotificationInterface): void => {
    handleSaveNotificationSettings({
      ...values,
      error_notification_url: notificationSettings?.error_notification_url,
    });
    setSaveType("notification-throttle");
    if (values.is_error_throttling_enabled) {
      trackErrorNotification(
        errorNotificationEvents.MUTE_ERROR_NOTIFICATION_SETUP,
        `${values.error_throttling_duration} hours`
      );
    }
  };

  return (
    <>
      <div className="error-notification-panel">
        <>
          <div
            className="settings-container"
            style={{
              padding: "24px 0px 24px 0px",
              marginTop: "0px",
            }}
          >
            <div id="sdk-preview-container" className="sdk-preview-container" />
          </div>
          {isLoading ? (
            <IntegryLoader />
          ) : (
            <>
              <>
                <NotificationUrl
                  onSave={onWebhookUrlSave}
                  isSavingUrl={isSavingUrl}
                  notificationSettings={notificationSettings}
                />
                <NotificationThrottle
                  onSave={onSettingSave}
                  notificationSettings={notificationSettings}
                />
              </>
            </>
          )}
        </>

        <PopupContainer
          isOpen={showNotificationCreatedSuccess}
          title="All Done!"
          content={
            <>
              <p className="popup-content-data">
                You&apos;ve successfully set up error notifications for{" "}
                {integrationName}!
              </p>
              <p className="popup-content-data">
                You&apos;ll receive an example notification soon.
              </p>
            </>
          }
          popupIcon={
            <>
              <img src={allDoneIcon} alt="smartbox_icon" />
            </>
          }
          close={() => setShowNotificationCreatedSuccess(false)}
          overlayClassName="integration-created-popup-overlay"
        />
      </div>
    </>
  );
};

const WrappedErrorNotifications = connect(
  (state: { myApps: App[]; globallySelectedApp: number | null }) => {
    return {
      app: getMyAppById(state.myApps, state.globallySelectedApp),
    };
  }
)(ErrorNotifications);

const FlaggedErrorNotification = (props: IProps): ReactElement => (
  <FeatureFlag
    flagKey="example-notification-for-webhook-urls"
    renderFeatureCallback={() => (
      <WrappedErrorNotifications {...props} webhookURLSampleNotification />
    )}
    renderDefaultCallback={() => <WrappedErrorNotifications {...props} />}
  />
);
export default FlaggedErrorNotification;
