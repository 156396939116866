/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
// import Button from "components/common/reusable-button";
// import {
//   renderApiError,
//   renderSuccessToast,
// } from "components/common/toast-utils";
import FormikWrapper from "components/forms/v4/formik-wrapper";
import Label from "components/forms/v4/label";
import CodeViewer from "components/integry-design-system/molecules/code-viewer";
import RedactedInputField from "components/integry-design-system/molecules/redacted-input-field";
import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { IntegryJS, Helpers } from "@integry/sdk";
import { connect } from "react-redux";
import { getMyAppById } from "selectors/apps";
import DownloadImg from "images/download.png";
import config from "utils/config";
import { User } from "types/integry-user";
import * as yup from "yup";
import {
  genEmbedCode,
  genFullCode,
  genServerSideJS,
  genServerSidePhp,
  genServerSidePython,
} from "./sdk-constants";

const SDKSettingValidationSchema = yup.object().shape({});

interface IWorkspaceApp {
  key: string;
  secret: string;
  partnerAPIKey?: string;
}

interface ISDKSettingSection {
  workspaceApp?: IWorkspaceApp;
  user: User;
}

const SDKSettingSection = (props: ISDKSettingSection): ReactElement => {
  const { workspaceApp = { key: "", secret: "" }, user } = props;
  const { default_marketplace: defaultMarketplaceId, partner_api_key } = user;
  // const [isLoading, setIsLoading] = useState(true);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const bundleId = queryParams.get("bundle_id") || defaultMarketplaceId;
  let IntegrySDK: IntegryJS;
  const saveSettings = (formData): void => {
    // do nothing
  };

  const gotoSDKPreview = (): void => {
    window.open(
      `/wapp/marketplace/embed/sdk-preview?marketplaceId=${bundleId}`,
      "_blank"
    );
  };

  const downloadSample = (): void => {
    const { key, secret } = workspaceApp;

    const codeString = genFullCode(key, secret, `${bundleId}`);
    const element = document.createElement("a");
    const file = new Blob([codeString.replace(/\n/g, "\r\n")], {
      type: "text/html",
    });
    element.href = URL.createObjectURL(file);
    element.download = "sample.html";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const renderSDKModal = async (): Promise<null> => {
    const { username } = user;
    if (workspaceApp && Object.keys(workspaceApp).length > 0) {
      if (IntegrySDK) {
        IntegrySDK.destroy();
        IntegrySDK.renderMarketplace({
          containerId: "sdk-preview-modal",
          renderMode: IntegryJS.MarketplaceRenderModes.MODAL,
        });
      } else {
        const hash = await Helpers.getAuthHash(
          username,
          workspaceApp?.secret || ""
        );
        IntegrySDK = new IntegryJS({
          appKey: workspaceApp?.key,
          hash,
          userId: username,
          marketplaceId: `${bundleId}`,
          userConfig: {
            viewAsIU: true,
          },
          env: config.apiBaseURL?.includes("beta") ? "staging" : "production",
        });
        IntegrySDK.renderMarketplace({
          containerId: "sdk-preview-modal",
          renderMode: IntegryJS.MarketplaceRenderModes.MODAL,
        });
      }
    }
    return null;
  };

  const SDKSettingsForm = (): ReactElement => {
    // const { isValid } = useFormikContext() as {
    //   isValid: boolean;
    // };

    // const disableSaveBtn = !isValid;

    return (
      <div className="sdk-setting-form">
        <div className="field-wrap bottom-spacing">
          <Label
            title="App Key"
            forControl="app-key"
            // toolTip="Your app key for this embed."
            // isUnderlined
          />
          <RedactedInputField
            value={workspaceApp.key}
            tooltip="Show/hide App Key."
            inputClassName="integry-text-field"
          />
        </div>
        <div className="field-wrap bottom-spacing">
          <Label
            title="App Secret"
            forControl="app-secret"
            // toolTip="Your app secret for this embed."
            // isUnderlined
          />
          <RedactedInputField
            value={workspaceApp.secret}
            tooltip="Show/hide App Secret."
            inputClassName="integry-text-field"
          />
        </div>
        {partner_api_key && (
          <div
            className="divider"
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#E5E5E5",
              marginBottom: "40px",
            }}
          />
        )}
        {partner_api_key && (
          <div
            className="field-wrap"
            style={{
              marginBottom: "0px",
            }}
          >
            <Label
              title="Partner API Key"
              forControl="partner-api-key"
              // toolTip="Your partner API key."
              // isUnderlined
            />
            <RedactedInputField
              value={partner_api_key}
              tooltip="Show/hide partner API key."
              inputClassName="integry-text-field"
            />
          </div>
        )}
        {/* <div className="field-wrap bottom-spacing">
          <Label
            title="Marketplace ID"
            forControl="marketplace-id"
            toolTip="Every marketplace has a unique ID. This is used in the embed code."
            isUnderlined
          />
          <RedactedInputField
            value={`${bundleId}`}
            tooltip="Show/hide marketplace ID."
            initialVisible
            inputClassName="integry-text-field"
          />
        </div> */}
        {/* <div className="field-wrap bottom-spacing">
          <CodeViewer
            title="Server-side, Hash Generation Code"
            tooltip="Every SDK embed requires a unique hash in the embed code. You can use one of these samples for various languages. This should be server side. For testing purposes only, we have added a hash calculation helper below. Please replace it once done testing."
            editorHeight="220px"
            data={[
              {
                value: genServerSideJS(workspaceApp.secret),
                language: "javascript",
              },
              {
                value: genServerSidePhp(workspaceApp.secret),
                language: "php",
              },
              {
                value: genServerSidePython(workspaceApp.secret),
                language: "python",
              },
            ]}
          />
        </div>
        <div className="field-wrap bottom-spacing">
          <CodeViewer
            title="Embed Code"
            editorHeight="360px"
            showPreviewBtn
            onPreviewBtnClick={renderSDKModal}
            tooltip="Copy the SDK code and paste it in your web app wherever you want this marketplace to appear."
            data={[
              {
                value: genEmbedCode(
                  workspaceApp.key,
                  workspaceApp.secret,
                  `${bundleId}`
                ),
                language: "html",
              },
            ]}
            showPreviewBtn2
            onPreviewBtnClick2={gotoSDKPreview}
            previewBtnLabel2="Preview in Tab"
            previewBtnLabel="Preview in Modal"
          />
          <div id="sdk-preview-modal" />
        </div> */}
        {/* <div className="download-text-div">
          <span
            role="button"
            tabIndex={-1}
            onClick={downloadSample}
            style={{
              color: "#4250F0",
              cursor: "pointer",
            }}
          >
            <img src={DownloadImg} alt="download" width="16" height="16" />{" "}
            Download
          </span>
          <span> sample HTML file with SDK code</span>
        </div> */}

        {/* <Button
          title="Save"
          onClick={() => {
            // do nothing;
          }}
          className="btn btn-royal-blue save-btn"
          type="submit"
          isLoading={isSaving}
          disabled={disableSaveBtn}
        /> */}
      </div>
    );
  };

  return (
    <div className="section-card-wrap">
      <FormikWrapper
        initialState={{}}
        saveCallback={saveSettings}
        validationSchema={SDKSettingValidationSchema}
        formContent={<SDKSettingsForm />}
      />
    </div>
  );
};

const ConnectedSDKSettingSection = connect(
  (state: any, ownProps: ISDKSettingSection) => ({
    workspaceApp: getMyAppById(state.myApps, state.globallySelectedApp),
  }),
  null,
  null,
  { pure: false }
)(SDKSettingSection);

export default ConnectedSDKSettingSection;
