import http from "../../../../utils/http";

export const getTamplateUser = (ApiUrl) => {
  return http.get(ApiUrl);
};

export const getUserIntegrations = (ApiUrl) => {
  return http.get(ApiUrl);
};

export const getIntegrationSteps = (templateId, intgId, status) => {
  return http.get(
    `/api/v2/templates/${templateId}/integrations/${intgId}/?status=${status}`
  );
};

export const getRunTasks = (templateId, intgId, runId) => {
  return http.get(
    `/api/v2/templates/${templateId}/integrations/${intgId}/runs/${runId}/`
  );
};

export const getTaskDetails = (templateId, intgId, taskId) => {
  return http.get(
    `/api/v2/templates/${templateId}/integrations/${intgId}/tasks/${taskId}/`
  );
};

export const getTaskIterations = (templateId, intgId, taskId) => {
  return http.get(
    `/api/v2/templates/${templateId}/integrations/${intgId}/tasks/${taskId}/iterations`
  );
};
