import React, { Component, Fragment } from "react";
import inflection from "inflection";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import TaskElipsis from "./common-tasks-elipsis";

import { decodeJSON } from "../../../../utils/functions";

import moreIcon from "../../../../images/more.svg";
import logLink from "../../../../images/log-link.svg";
import appIconPlaceholder from "../../../../images/blank-emblem-icon.svg";

import triggerIcon from "../../../../images/templates-v2/trigger-icon.svg";
import actionIconOn from "../../../../images/action-icon-on.svg";
import ifIcon from "../../../../images/intg_view_if.svg";
import elseIfIcon from "../../../../images/templates-v2/else-icon.png";
import elseIcon from "../../../../images/templates-v2/else-icon.png";
import queryIcon from "../../../../images/query-icon.svg";
import adapterIcon from "../../../../images/adapter-icon.svg";
import filterIcon from "../../../../images/green-filter-icon.svg";
import loopIcon from "../../../../images/intg_view_loop.svg";
import setupIcon from "../../../../images/intg_view_setup_ico.svg";
import scheduleIcon from "../../../../images/templates-v2/schedule-icon.svg";
import StorageIcon from "../../../../images/intg_view_storage.svg";

class CommonTaskComponent extends Component {
  state = {
    showElipsis: false,
    showStepApps: false,
  };

  getBadgeClass = (status) => {
    let parsedStatusCode = parseInt(status);

    switch (status) {
      case parsedStatusCode < 400:
        return "badge-active";
      default:
        return "badge-inactive";
    }
  };

  getDuration = () => {
    let { run_at } = this.props;
    let currentTime = new Date().getTime();
    let difference = currentTime - run_at;
    let time = new Date(difference).toISOString().substr(11, 8).split(":");
    let formatedTime = `${time[0] === "00" ? "" : `${time[0]}h `}`;
    formatedTime = `${formatedTime} ${time[1] === "00" ? "" : `${time[1]}m `}`;
    formatedTime = `${formatedTime} ${time[2] === "00" ? "" : `${time[2]}s `}`;
    return formatedTime;
  };

  getCompleteDate = (timestamp) => {
    let a = new Date(timestamp);
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate() < 10 ? `0${a.getDate()}` : a.getDate();
    let hour = a.getHours() < 10 ? `0${a.getHours()}` : a.getHours();
    let min = a.getMinutes() < 10 ? `0${a.getMinutes()}` : a.getMinutes();
    let time = `${hour} : ${min} PKT \n ${month} ${date}, ${year}`;
    return time;
  };

  getCustomStyle = () => {
    const {
      internalStep,
      isLast,
      isDetailsVisible,
      showBorder = true,
    } = this.props;
    let customStyle = {};
    if (internalStep && isLast) {
      customStyle["borderBottom"] = 0;
    }
    if (isDetailsVisible) {
      customStyle["backgroundColor"] = "#ffffff";
      customStyle["borderBottomRightRadius"] = 0;
      customStyle["borderBottomLeftRadius"] = 0;
    }
    if (!showBorder) {
      customStyle["borderBottomLeftRadius"] = 0;
    }
    return customStyle;
  };

  toggleElipsis = (showElipsis) => {
    this.setState({ showElipsis: showElipsis });
  };
  toggleStepApps = (showStepApps) => {
    this.setState({ showStepApps: showStepApps, showElipsis: false });
  };

  getStepTitle = () => {
    const { activity, fields_default_value } = this.props;
    const { type, app, title: activityTile } = activity || {};
    const { name } = app;
    switch (type.toUpperCase()) {
      case "LOOP":
        return "Loop App";
      case "SCHEDULE":
        return "Schedule Task";
      case "TRIGGER":
        return `When ${activityTile} in ${name}`;
      case "CONDITION_IF":
      case "CONDITION_ELSEIF":
        const defaultVlue = decodeJSON(fields_default_value);
        const condition = defaultVlue.conditions
          ? defaultVlue.conditions[0]
          : {};
        return `IF "${condition.operand_1}" ${condition.operator} "${condition.operand_2}"`;
      default:
        return `${activityTile} in ${name}`;
    }
  };
  getStepIcon = (activityType) => {
    let defaultIcon;

    switch (activityType) {
      case "ACTION":
        defaultIcon = actionIconOn;
        break;
      case "QUERY":
        defaultIcon = queryIcon;
        break;
      case "LOOP":
        defaultIcon = loopIcon;
        break;
      case "FILTER":
        defaultIcon = filterIcon;
        break;
      case "ADAPTER":
        defaultIcon = adapterIcon;
        break;
      case "TRIGGER":
        defaultIcon = triggerIcon;
        break;
      case "CONDITION_IF":
        defaultIcon = ifIcon;
        break;
      case "CONDITION_ELSEIF":
        defaultIcon = elseIfIcon;
        break;
      case "CONDITION_ELSE":
        defaultIcon = elseIcon;
        break;
      case "SETUP":
        defaultIcon = setupIcon;
        break;
      case "SCHEDULE":
        defaultIcon = scheduleIcon;
        break;
      case "STORAGE":
        defaultIcon = StorageIcon;
        break;
      default:
        defaultIcon = actionIconOn;
    }

    return defaultIcon;
  };
  getIterationName = () => {
    const { index } = this.props;
    switch (index) {
      case 0:
        return `0th iteration`;
      case 1:
        return `1st iteration`;
      case 2:
        return `2nd iteration`;
      case 3:
        return `3rd iteration`;
      default:
        return `${index}th iteration`;
    }
  };

  render() {
    const {
      title,
      run_at,
      task_count,
      duration_count,
      log_link,
      status,
      status_message,
      integration_step_guid,
      apps,
      tasks,
      internalStep,
      iteration,
      hasInternalSteps,
      app_logo_url,
      user,
    } = this.props;
    const statusBadgeClass = this.getBadgeClass(status || "Inactive");
    const { showElipsis } = this.state;
    const customStyle = this.getCustomStyle();
    const stepTitle = title;
    const type = "";
    const icon_url = app_logo_url;

    return (
      <Fragment>
        <div
          className={`integration-runs-tasks ${tasks ? "integration-tasks" : ""}
          ${internalStep ? "tasks-internal-steps" : ""}
          `}
          style={customStyle}
        >
          <div className="run-image-container">
            <div className="run-image">
              <img
                src={icon_url || appIconPlaceholder}
                alt=""
                height={icon_url ? 30 : 20}
                width={icon_url ? 30 : 20}
                style={icon_url ? {} : { borderRadius: 0 }}
              />
            </div>
          </div>
          <div
            style={{
              marginLeft: "-8px",
              color: status > 299 ? "#F05C42" : "#333333",
            }}
          >
            <span
              className={"user-name"}
              style={{
                color: tasks && status > 299 ? "#F05C42" : "#333333",
              }}
            >
              {stepTitle}
            </span>
            {iteration && type === "loop" && (
              <span
                style={{ fontSize: 10, marginLeft: 12, opacity: 0.7 }}
                className={"user-name"}
              >
                {this.getIterationName()}
              </span>
            )}
          </div>

          <OverlayTrigger
            key={`overlay`}
            placement={"bottom"}
            overlay={
              <Tooltip id={`tooltip`} style={{ fontSize: 11, width: 90 }}>
                {this.getCompleteDate(run_at)}
              </Tooltip>
            }
          >
            <div className="user-name text-center">{`${this.getDuration()} ago`}</div>
          </OverlayTrigger>

          <div className="user-name text-center">{task_count}</div>
          <div className="user-name text-center">{`${duration_count}s`}</div>
          {user && user.can_view_logs && (
            <div
              className="align-center"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {!tasks && user && user.can_view_logs && (
                <CopyToClipboard text={log_link || "#"}>
                  <img src={logLink} alt="" height="16" width="15" />
                </CopyToClipboard>
              )}
            </div>
          )}

          <div className="user-name text-center">
            <OverlayTrigger
              key={`overlay`}
              placement={"bottom"}
              overlay={
                <Tooltip id={`tooltip`} style={{ fontSize: 12 }}>
                  {status_message}
                </Tooltip>
              }
            >
              <span className={`badge user-status-badge ${statusBadgeClass}`}>
                {inflection.titleize((status || "Inactive").toString())}
              </span>
            </OverlayTrigger>
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ height: "100%" }}
          >
            {hasInternalSteps && (
              <OverlayTrigger
                key={`overlay`}
                placement={"right"}
                overlay={
                  <Tooltip
                    id={`tooltip-internal steps`}
                    style={{ fontSize: 12 }}
                  >
                    Task has internal steps
                  </Tooltip>
                }
              >
                <FontAwesomeIcon
                  style={{
                    float: "right",
                    marginTop: 4,
                    marginRight: 7,
                    opacity: 0.4,
                  }}
                  icon="info-circle"
                />
              </OverlayTrigger>
            )}
            <img
              onClick={() => this.toggleElipsis(true)}
              src={moreIcon}
              alt=""
              height="16"
              width="16"
              style={{ marginTop: 28 }}
            />
            <TaskElipsis
              showElipsis={showElipsis}
              step_guid={integration_step_guid}
              apps={apps}
              toggleElipsis={this.toggleElipsis}
              icon_url={icon_url}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CommonTaskComponent;
