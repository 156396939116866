import React, { Component, Fragment } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-textmate";

import { getTaskDetails } from "./template-view-apis";

import CommonTaskComponent from "./common-tasks-componnet";

class TaskWithDetails extends Component {
  state = {
    showDetails: false,
    fetchingTaskDetails: false,
    tasKdetails: {},
    selectedDetailKey: "parsed_url",
  };
  toggleDetails = () => {
    const { showDetails } = this.state;
    if (showDetails) {
      this.setState((prevState) => ({ showDetails: !prevState.showDetails }));
    } else {
      this.getTaskDetails();
    }
  };
  setSelectedDetails = (key) => {
    this.setState({ selectedDetailKey: key });
  };
  getTaskDetails = () => {
    const { integrationId, templateId, step_guid } = this.props;
    this.setState({ fetchingTaskDetails: true, showDetails: true });
    getTaskDetails(templateId, integrationId, step_guid).then((res) => {
      this.setState({
        tasKdetails: res.data,
        fetchingTaskDetails: false,
      });
    });
  };
  render() {
    const { showDetails, tasKdetails, selectedDetailKey, fetchingTaskDetails } =
      this.state;
    const selectedDetail = tasKdetails[selectedDetailKey] || {};
    return (
      <Fragment>
        <div onClick={this.toggleDetails}>
          <CommonTaskComponent {...this.props} isDetailsVisible={showDetails} />
        </div>
        {showDetails && (
          <div className={"task-details-container"}>
            <div className={"task-deatials-inner-container"}>
              <div className="task-deatials-headings">
                <div
                  className={`item ${
                    selectedDetailKey === "parsed_url" ? "selected-item" : ""
                  }`}
                  onClick={() => this.setSelectedDetails("parsed_url")}
                >
                  Request URI
                </div>
                <div
                  className={`item ${
                    selectedDetailKey === "request_template"
                      ? "selected-item"
                      : ""
                  }`}
                  onClick={() => this.setSelectedDetails("request_template")}
                >
                  Request Body
                </div>
                <div
                  className={`item ${
                    selectedDetailKey === "parsed_request_template"
                      ? "selected-item"
                      : ""
                  }`}
                  onClick={() =>
                    this.setSelectedDetails("parsed_request_template")
                  }
                >
                  Parsed Request Body
                </div>
                <div
                  className={`item ${
                    selectedDetailKey === "remote_response"
                      ? "selected-item"
                      : ""
                  }`}
                  onClick={() => this.setSelectedDetails("remote_response")}
                >
                  Response Body{" "}
                </div>
                <div
                  className={`item ${
                    selectedDetailKey === "parsed_object_output"
                      ? "selected-item"
                      : ""
                  }`}
                  onClick={() =>
                    this.setSelectedDetails("parsed_object_output")
                  }
                >
                  Object Output
                </div>
                <div
                  className={`item ${
                    selectedDetailKey === "parsed_response_template"
                      ? "selected-item"
                      : ""
                  }`}
                  onClick={() =>
                    this.setSelectedDetails("parsed_response_template")
                  }
                >
                  Request Headers
                </div>
                <div
                  className={`item ${
                    selectedDetailKey === "response_headers"
                      ? "selected-item"
                      : ""
                  }`}
                  onClick={() => this.setSelectedDetails("response_headers")}
                >
                  Response Headers
                </div>
              </div>
              <div className={"task-details-bottom-section"}>
                <div className={"task-deatials-data"}>
                  {fetchingTaskDetails ? (
                    <div className={"spinner-container"}>
                      <div className="integry-spinner-sm" />
                    </div>
                  ) : (
                    <AceEditor
                      placeholder="Placeholder Text"
                      mode="json"
                      theme="textmate"
                      className="datap"
                      name="blah2"
                      fontSize={12}
                      readOnly={true}
                      showGutter={true}
                      height={"100%"}
                      width={"100%"}
                      wrapEnabled={true}
                      value={JSON.stringify(selectedDetail, null, "\t")}
                      showPrintMargin={false}
                      setOptions={{
                        showLineNumbers: true,
                        tabSize: 1,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default TaskWithDetails;
