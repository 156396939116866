import React, { Component, Fragment } from "react";
import TaskWithDetails from "./task-with-details";
import { getTaskIterations } from "./template-view-apis";

import loopIcon from "../../../../images/intg_view_loop.svg";
class RunsTasks extends Component {
  state = {
    moreIterations: { items: [] },
    gettingIterations: false,
  };
  getMoreIterations = () => {
    const { integrationId, templateId, step_guid } = this.props;
    this.setState({ gettingIterations: true });
    getTaskIterations(integrationId, templateId, step_guid).then((res) => {
      this.setState((prevState) => ({
        gettingIterations: false,
        moreIterations: {
          ...res.data,
          items: [...prevState.moreIterations.items, ...res.data.items],
        },
      }));
    });
  };
  LoadMoreStepPlaceHolder = () => {
    const { gettingIterations } = this.state;
    return (
      <div
        className={"runs-tasks-nested-container"}
        style={{ gridColumn: 2 }}
        onClick={this.getMoreIterations}
      >
        <div className={"integration-runs-tasks integration-tasks"}>
          <div className="run-image-container">
            <div className="run-image">
              <img
                src={loopIcon}
                alt=""
                height="20"
                width="20"
                style={{ borderRadius: 0 }}
              />
            </div>
          </div>
          <div style={{ marginLeft: "-15px" }}>
            <span className={"user-name"}>Loop App</span>
          </div>
          {gettingIterations ? (
            <div
              className={"spinner-container"}
              style={{ gridColumn: 3, marginTop: 0 }}
            >
              <div className="integry-spinner-sm" />
            </div>
          ) : (
            <div className={"load-Integrations"}>Load More Integrations</div>
          )}
        </div>
      </div>
    );
  };

  showBorder = (step) => {
    const { hasMoreSteps } = this.props;
    if (step.steps && step.steps.length > 0) return true;
    else if (hasMoreSteps === "true") {
      return false;
    }
    return false;
  };
  render() {
    const {
      steps,
      showInternalSteps,
      root = false,
      //ShowDottedLine = true,
      iteration = false,
      integrationId,
      templateId,
      step_guid,
    } = this.props;
    const { moreIterations } = this.state;
    return (
      <Fragment>
        {steps.map((step, index) => (
          <Fragment>
            {step.is_iterable ? (
              <Fragment>
                <RunsTasks
                  steps={[...step.items, ...moreIterations.items]}
                  showInternalSteps={showInternalSteps}
                  ShowDottedLine={false}
                  iteration={true}
                  // key={step.id}
                />
                {this.LoadMoreStepPlaceHolder()}
              </Fragment>
            ) : (
              <div
                className={"runs-tasks-nested-container"}
                style={!root ? { gridColumn: 2 } : {}}
              >
                <div className={"doted-line-container"}>
                  {false && <div className={"doted-line"}></div>}
                </div>
                <TaskWithDetails
                  {...step}
                  tasks={true}
                  index={index}
                  iteration={iteration}
                  showBorder={this.showBorder(step, steps.length - 1 === index)}
                  hasInternalSteps={
                    step.internal_steps && step.internal_steps.length > 0
                  }
                />
                {showInternalSteps &&
                  step.internal_steps &&
                  step.internal_steps.length > 0 && (
                    <div className={"internal-step-container"}>
                      {step.internal_steps.map((internal_step, index) => (
                        <TaskWithDetails
                          {...internal_step}
                          internalStep={true}
                          isLast={
                            step.internal_steps &&
                            step.internal_steps.length - 1 === index
                          }
                          integrationId={integrationId}
                          templateId={templateId}
                          step_guid={step_guid}
                        />
                      ))}
                    </div>
                  )}
                {step.steps && step.steps.length > 0 && (
                  <RunsTasks
                    steps={step.steps}
                    showInternalSteps={showInternalSteps}
                    hasMoreSteps={steps.length - 1 === index ? "false" : "true"}
                  />
                )}
              </div>
            )}
          </Fragment>
        ))}
      </Fragment>
    );
  }
}

export default RunsTasks;
