/* eslint-disable react/require-default-props */
import "./styles.scss";

import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import IntegryTooltip, {
  IntegryTooltipProps,
} from "components/common/tooltip-wrappers/integry-tooltip";

import Typography from "../typography";
import LoadingIcon from "./LoadingIcon";

export interface ButtonProps {
  size?: "small" | "medium" | "large";
  variant?: "solid" | "outline";
  variation?: "primary" | "cancel" | "delete" | "link";
  wide?: boolean;
  icon?: string;
  isLoading?: boolean;
  renderIconRight?: boolean;
  disabled?: boolean;
  tooltip?: string;
  tooltipPlacement?: IntegryTooltipProps["placement"];
  disableTooltip?: IntegryTooltipProps["disabled"];
  action?: (e: React.FormEvent<EventTarget>) => void;
  className?: string;
  children?: string | React.ReactNode;
  style?: React.CSSProperties;
  type?: "button" | "submit" | "reset";
}

const Variation_Colors = {
  primary: "#4250F0",
  cancel: "#F0F0F0",
  delete: "#F05C42",
};

const Button: React.FC<ButtonProps> = forwardRef(
  (
    {
      children = "Button",
      size = "medium",
      variant = "solid",
      variation = "primary",
      isLoading = false,
      wide = false,
      icon = undefined,
      renderIconRight = false,
      disabled = false,
      tooltip = "",
      tooltipPlacement = "bottom",
      disableTooltip = false,
      action = () => null,
      className,
      ...rest
    },
    ref: React.Ref<HTMLButtonElement> | null
  ) => {
    // console.log("props i am getting", size, variant, variation);
    return (
      <IntegryTooltip
        tooltipText={tooltip}
        hideToolTip={!tooltip || disableTooltip}
        placement={tooltipPlacement}
      >
        <div className="reusable-button-wrapper">
          <button
            className={`button ${size} ${variation} ${variant}
          ${variant}-${variation}
          ${size}-with-${icon ? "icon" : ""}
          ${renderIconRight ? "render-icon-right" : ""}
          ${size}-${wide ? "wide" : ""}
          ${className || ""}`}
            type="button"
            {...rest}
            ref={ref}
            disabled={disabled}
            onClick={(e) => !isLoading && action(e)}
          >
            {!isLoading && icon && <img src={icon} alt="button-icon" />}
            {isLoading ? (
              <LoadingIcon
                color={
                  variant === "outline" ? Variation_Colors[variation] : "#fff"
                }
              />
            ) : (
              <Typography variation="3" color="inherit">
                {children}
              </Typography>
            )}
          </button>
        </div>
      </IntegryTooltip>
    );
  }
);

Button.displayName = "Button";
Button.propTypes = {
  size: PropTypes.oneOf(["medium", "small", "large"]),
  variant: PropTypes.oneOf(["solid", "outline"]),
  variation: PropTypes.oneOf(["primary", "cancel", "delete"]),
  wide: PropTypes.bool,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
  renderIconRight: PropTypes.bool,
  disabled: PropTypes.bool,
  action: PropTypes.func,
};

export default Button;
