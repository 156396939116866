import { CSSProperties, HTMLAttributes } from "react";
import OverflowTooltip from "components/common/overflow-tooltip";
import "./styles.scss";

// type RGB = `rgb(${number}, ${number}, ${number})`;
// type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
// type HEX = `#${string}`;

// type Color = RGB | RGBA | HEX;

const colorSchemes = {
  template: {
    draft: {
      color: "#5194F8",
      background: "#DCEAFF",
    },
    published: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    unlisted: {
      color: "#FFFFFF",
      background: "#FCC700",
    },
    live: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    "200": {
      color: "#749D48",
      background: "#DFF4C8",
    },
    "503": {
      color: "#F05C42",
      background: "#FBD6D0",
    },
    runs_automatically: {
      color: "#4250F0",
      background: "#EEEEFF",
    },
  },
  integration: {
    enabled: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    active: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    disabled: {
      color: "#999999",
      background: "#F1F0ED",
    },
    paused: {
      color: "#9D7C00",
      background: "#FFECA6",
    },
    blocked: {
      color: "#F05C42",
      background: "#FBD6D0",
    },
    deleted: {
      color: "#F05C42",
      background: "#FBD6D0",
    },
    runs_automatically: {
      color: "#999999",
      background: "#F1F0ED",
    },
    runs_once: {
      color: "#999999",
      background: "#F1F0ED",
    },
    "runs once": {
      color: "#999999",
      background: "#F1F0ED",
    },
    creation_in_progress: {
      color: "#5194F8",
      background: "#DCEAFF",
    },
    "initializing...": {
      color: "#5194F8",
      background: "#DCEAFF",
    },
    creation_failed: {
      color: "#F05C42",
      background: "#FBD6D0",
    },
    failed_to_initialize: {
      color: "#F05C42",
      background: "#FBD6D0",
    },
  },
  run: {
    ok: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    completed: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    filtered_green: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    filtered: {
      color: "#999999",
      background: "#F1F0ED",
    },
    failed: {
      color: "#F05C42",
      background: "#FBD6D0",
    },
    true: {
      color: "#666666",
      background: "transparent",
      fontWeight: "500",
      padding: "0",
    },
    false: {
      color: "#666666",
      background: "transparent",
      fontWeight: "500",
      padding: "0",
    },
    aborted: {
      color: "#F05C42",
      background: "#FBD6D0",
    },
    bad_request: {
      color: "#F05C42",
      background: "#FBD6D0",
    },
    "hasn't_run": {
      color: "#999999",
      background: "#F1F0ED",
    },
    no_runs_as_yet: {
      color: "#999999",
      background: "#FFF",
      padding: "0",
    },
    "no runs as yet": {
      color: "#999999",
      background: "#FFF",
      padding: "0",
    },
    has_not_run: {
      color: "#999999",
      background: "#F1F0ED",
    },
    "n/a": {
      color: "#666666",
      background: "transparent",
      fontWeight: "500",
      padding: "0",
    },
    "...": {
      color: "#666666",
      background: "#FFF",
    },
    "200": {
      color: "#749D48",
      background: "#DFF4C8",
    },
    "503": {
      color: "#F05C42",
      background: "#FBD6D0",
    },
    auth_missing: {
      color: "#F05C42",
      background: "#FBD6D0",
    },
    running: {
      color: "#5194F8",
      background: "#DCEAFF",
    },
    "running...": {
      color: "#5194F8",
      background: "#DCEAFF",
    },
    visible: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    hidden: {
      color: "#5194F8",
      background: "#DCEAFF",
    },
    archived: {
      color: "#999999",
      background: "#F1F0ED",
    },
  },
  copy: {
    copy_guid: {
      color: "#FFFFFF",
      background: "#333333",
    },
  },
  app: {
    draft: {
      color: "#666666",
      background: "#E7E7E7",
    },
    visible: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    live: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    hidden: {
      color: "#5194F8",
      background: "#DCEAFF",
    },
    archived: {
      color: "#999999",
      background: "#F1F0ED",
    },
  },
  deployment: {
    live: {
      color: "#FFFFFF",
      background: "#78D612",
    },
    draft: {
      color: "#FFFFFF",
      background: "#999999",
    },
  },
  activity: {
    published: {
      color: "#FFFFFF",
      background: "#7ED321",
    },
  },
  team_member: {
    invited: {
      color: "#5194F8",
      background: "#DCEAFF",
    },
  },
  bundle_app: {
    archived: {
      color: "#FFFFFF",
      background: "#F05C42",
    },
    hidden: {
      color: "#999999",
      background: "#F1F0ED",
    },
    visible: {
      color: "#749D48",
      background: "#DFF4C8",
    },
    basic: {
      color: "#4F4F4F",
      background: "#E9ECEE",
    },
    beta: {
      color: "#FFFFFF",
      background: "#FFA000",
    },
    premium: {
      color: "#FFFFFF",
      background: "#673AB7",
    },
  },
  bundle_app_action: {
    archived: {
      color: "#FFFFFF",
      background: "#F05C42",
    },
  },
  flat_actions: {
    archived: {
      color: "#EB5757",
      background: "rgba(235, 87, 87, 0.10)",
    },
  },
  grey: {
    color: "rgb(79, 79, 79)",
    background: "rgb(233, 236, 238)",
    fontWeight: 400,
    fontSize: 10,
    lineHeight: "12px",
    textAlign: "center",
    padding: "4px 9px",
    height: 20,
  },
  royalBlue: {
    color: "#4250F0",
    background: "#EEEEFF",
    fontWeight: 400,
    fontSize: 10,
    lineHeight: "12px",
    textAlign: "center",
    padding: "4px 9px",
    height: 20,
  },
};

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  customStyle?: CSSProperties;
  displayLabel?: string;
  truncate?: boolean /* to enable truncate with tooltip, pass max witdth to tag component customStyle={{ maxWidth: "120px", }} */;
  type:
    | "template"
    | "integration"
    | "run"
    | "copy"
    | "app"
    | "deployment"
    | "activity"
    | "team_member"
    | "bundle_app"
    | "bundle_app_action"
    | "flat_actions"
    | "grey"
    | "royalBlue";
}

const Tag = ({ customStyle = {}, ...props }: IProps): JSX.Element => {
  const { label, type, displayLabel, truncate } = props;
  const sLabel = `${label}`;
  const defaultColorTheme =
    (colorSchemes[type] || { template: {} })[
      sLabel.toLowerCase().split(" ").join("_")
    ] ||
    colorSchemes[type] ||
    {};
  const tempLabel = labelDictionary[sLabel.toLowerCase()] || sLabel;
  const truncateStyle = truncate ? { minWidth: "unset", display: "block" } : {};

  return label ? (
    <>
      {truncate ? (
        <OverflowTooltip
          className={`integry-tag integry-tag__${type}`}
          style={{ ...defaultColorTheme, ...customStyle, ...truncateStyle }}
          text={displayLabel || tempLabel || sLabel}
        />
      ) : (
        <div
          className={`integry-tag integry-tag__${type}`}
          style={{ ...defaultColorTheme, ...customStyle }}
        >
          {displayLabel || tempLabel || sLabel}
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default Tag;

const labelDictionary = {
  has_not_run_old: "Hasn't run",
  has_not_run: "No runs as yet",
  runs_automatically: "Runs automatically",
};
