import { createApi } from "@reduxjs/toolkit/query/react";
import config from "utils/config";
import { integryBaseQuery } from "utils/http";
import { ListedTemplate } from "./template-builder-interface";

interface listTemplatesResponse {
  count: number;
  links: { previous?: string; next?: string };
  meta: {
    status_counts: { status: string; count: number }[];
    template_apps: { app_id: number; app_name: string }[];
  };
  results: ListedTemplate[];
  total_pages: number;
}

export const flowApi = createApi({
  reducerPath: "flowApi",
  baseQuery: integryBaseQuery({
    baseUrl: config.apiBaseURL || "",
  }),
  tagTypes: ["flowListing"],
  refetchOnMountOrArgChange: true, // this is to be removed once the other flow create/update endpoints are moved to rtk
  endpoints(build) {
    return {
      listTemplates: build.query<listTemplatesResponse, string>({
        query: (queryParams) => ({
          url: `/api/v1/templates/${queryParams ? `?${queryParams}` : ""}`,
          method: "get",
        }),
        providesTags: ["flowListing"],
      }),

      listTemplatesWithStats: build.mutation<
        listTemplatesResponse,
        {
          searchText: string;
          page: number;
          showArchived: boolean;
          appId: number | string;
          ordering: string;
          usage_stats: boolean;
        }
      >({
        query: ({
          searchText,
          page,
          showArchived,
          usage_stats,
          ordering,
          appId,
        }) => ({
          url: `/api/v1/templates/?version_lte=7&app=${appId}&page_size=15${
            showArchived ? "&show_archived=true" : ""
          }&page=${page}&search=${encodeURIComponent(
            searchText
          )}&ordering=${ordering}
          ${usage_stats ? "&usage_stats=true" : ""}`,
          method: "get",
        }),
      }),
      cloneFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/v2/templates/${flowId}/duplicate/`,
            method: "POST",
          };
        },
      }),
      addFlowActions: build.mutation({
        query({ workspaceAppId, templates, actionPayload }) {
          return {
            url: `/api/v1/templates/action_flows/?app=${workspaceAppId}`,
            method: "POST",
            data: {
              templates,
              action_payload: actionPayload,
            },
          };
        },
      }),
      pollCloningActionFlows: build.mutation({
        query({ workspaceAppId }) {
          return {
            url: `/api/v1/templates/flows_in_cloning/?app=${workspaceAppId}`,
            method: "GET",
          };
        },
      }),
      archiveFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/templates/${flowId}/?publish=false&archive=true`,
            method: "PUT",
          };
        },
      }),
      unArchiveFlow: build.mutation({
        query(flowId) {
          return {
            url: `/api/templates/${flowId}/?publish=false`,
            method: "PUT",
          };
        },
      }),
      moveFlow: build.mutation({
        query({ flowId, ...data }) {
          return {
            url: `/api/v2/templates/${flowId}/app/`,
            method: "PUT",
            data,
          };
        },
      }),
      convertActivity: build.mutation({
        query({ appId, stepId }) {
          return {
            url: `/api/v1/apps/${appId}/activities/?template_step_id=${stepId}`,
            method: "POST",
          };
        },
      }),

      setFlowVisiblityInMarketplace: build.mutation({
        query({ templateId, isVisible }) {
          return {
            url: `/api/v1/marketplace-templates/${templateId}/`,
            method: "PATCH",
            data: { is_visible_in_marketplace: isVisible },
          };
        },
      }),
    };
  },
});

export const {
  useListTemplatesQuery,
  useListTemplatesWithStatsMutation,
  useCloneFlowMutation,
  useMoveFlowMutation,
  useConvertActivityMutation,
  useArchiveFlowMutation,
  useUnArchiveFlowMutation,
  useLazyListTemplatesQuery,
  useAddFlowActionsMutation,
  usePollCloningActionFlowsMutation,
  useSetFlowVisiblityInMarketplaceMutation,
} = flowApi;
