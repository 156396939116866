/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ReactElement } from "react";
import ReactModal from "react-modal";

import Button from "components/common/reusable-button";

import cancelIcon from "images/template-cancel-icon.png";
import PreloadImages from "components/common/preload-images";

interface CommonIntegryModalProps {
  show: boolean;
  close: () => void;
  title: string;
  message: string;
  icon: string;
  action: () => void;
  isLoading: boolean;
  actionButtonTitle?: string;
  modalClass?: string;
  isError?: boolean;
}

const CommonIntegryModal = (props: CommonIntegryModalProps): ReactElement => {
  const {
    show,
    close,
    title,
    message,
    icon,
    action,
    isLoading,
    actionButtonTitle,
    modalClass,
    isError = false,
  } = props;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <ReactModal
        isOpen={show}
        onRequestClose={close}
        shouldCloseOnOverlayClick={false}
        overlayClassName="IntegryModal__Overlay"
        className={`IntegryModal__Content common-integry-modal ${modalClass}`}
      >
        <div>
          <img
            onClick={close}
            src={cancelIcon}
            alt="Cancel"
            className="float-right cursor-pointer cancel-icon"
          />
        </div>
        <div className="content-div">
          <div className="icon">
            <img src={icon} className="modal-icon" alt="alert-icon" />
          </div>
          <div>
            <div className="inner-div">
              {title && <h2 className="title">{title}</h2>}
              {message && <p className="message">{message}</p>}
            </div>
            {isError ? (
              <Button
                className="btn integry-modal-btn btn-orange btn-orange-error"
                onClick={close}
                title="Go Back"
              />
            ) : (
              <>
                <button
                  className="btn integry-modal-btn btn-grey"
                  type="button"
                  onClick={close}
                >
                  Cancel
                </button>
                <Button
                  className="btn integry-modal-btn btn-orange"
                  onClick={action}
                  title={actionButtonTitle || "Delete"}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </>
            )}
          </div>
        </div>
      </ReactModal>
      <PreloadImages images={[icon, cancelIcon]} />
    </div>
  );
};

export default CommonIntegryModal;
