/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { RefObject, useEffect } from "react";

type AnyEvent = MouseEvent | TouchEvent;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void,
  ignoreClass?: string
) {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current;
      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      if (ignoreClass) {
        // Check if the clicked element or any ancestor has the ignore class
        let targetElement = event.target as HTMLElement | null;
        while (targetElement) {
          if (targetElement.classList.contains(ignoreClass)) {
            return; // Ignore the click
          }
          targetElement = targetElement.parentElement;
        }
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };

    // Reload only if ref or handler changes
  }, [ref, handler, ignoreClass]);
}

export default useOnClickOutside;
