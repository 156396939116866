/* eslint-disable react/jsx-no-undef */
import React, { ReactElement } from "react";
import { Field, FastField } from "formik";
import { FormFeedback } from "reactstrap";
import ReactSelect, { components } from "react-select";
import ReactCreatableSelect from "react-select/lib/Creatable";

import cancel from "images/cancel.svg";
import cancelV3 from "images/cancel-v3.svg";
import Dropdown from "images/Dropdown.svg";
import DropdownV3 from "images/select-dropdown.svg";
import EditOptionIcon from "images/edit-option-icon.svg";

const { Option, ValueContainer, Placeholder } = components;

const IconOption = (props): ReactElement => {
  const { data } = props;
  return (
    <>
      <Option {...props}>
        <span
          style={
            data.type
              ? {
                  display: "grid",
                  gridTemplateColumns: "25px 200px 10px",
                  // gridGap: '5px'
                }
              : {}
          }
        >
          {data.icon_url ? (
            <img
              src={data.icon_url}
              alt="App icon"
              className="select-app-icon pr-3"
            />
          ) : (
            <div />
          )}

          <span>{data.label}</span>
          {data.type && (
            <span style={{ font: "Inter", fontSize: "12px", color: "#666666" }}>
              {data.type}
            </span>
          )}
        </span>
      </Option>
    </>
  );
};

const TypeOption = (props): ReactElement => {
  const { data } = props;
  return (
    <>
      <Option {...props}>
        <span className="select-option-with-type">
          <span className="option-label">{data.label}</span>
          {data.type && <span className="option-type">{data.type}</span>}
        </span>
      </Option>
    </>
  );
};

const IconSelectedSingleValue = (props): ReactElement => {
  const { typeProperty, data } = props;
  return (
    <>
      <div
        style={
          typeProperty === "type"
            ? {
                display: "flex",
                flex: "1",
                flexWrap: "unset",
                alignItems: "center",
              }
            : {
                display: "flex",
                flex: "1",
                flexWrap: "unset",
                alignItems: "center",
                paddingBottom: "5px",
              }
        }
      >
        {data.icon_url && (
          <img
            src={data.icon_url}
            alt="App icon"
            className="select-app-icon pr-3"
          />
        )}
        <span>{data.label}</span>
      </div>
    </>
  );
};

const ClearIndicator = (props): ReactElement => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <img
        style={{
          height: "12px",
          width: "8px",
        }}
        src={cancel}
        alt="cancel"
      />
    </div>
  );
};

const ClearIndicatorv3 = (): ReactElement => {
  return (
    <div>
      <img src={cancelV3} alt="cancel" />
    </div>
  );
};

const CustomValueContainer = ({ children, ...props }): ReactElement => {
  return (
    <ValueContainer
      clearValue={props?.clearValue}
      cx={props?.cx}
      getStyles={props?.getStyles}
      getValue={props?.getValue}
      hasValue={props?.hasValue}
      isMulti={props?.isMulti}
      isRtl={props?.isRtl}
      options={props?.options}
      selectOption={props?.selectOption}
      selectProps={props?.selectProps}
      setValue={props?.setValue}
      theme={props?.theme}
      {...props}
    >
      <Placeholder
        isDisabled={props?.isDisabled}
        innerProps={props?.innerProps}
        clearValue={props?.clearValue}
        cx={props?.cx}
        getStyles={props?.getStyles}
        getValue={props?.getValue}
        hasValue={props?.hasValue}
        isMulti={props?.isMulti}
        isRtl={props?.isRtl}
        options={props?.options}
        selectOption={props?.selectOption}
        selectProps={props?.selectProps}
        setValue={props?.setValue}
        theme={props?.theme}
        {...props}
        isFocused={props.isFocused}
      >
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const DropdownIndicator = (props): ReactElement => {
  return (
    <components.DropdownIndicator {...props}>
      {" "}
      <img
        style={{
          height: "15px",
          width: "15px",
        }}
        src={Dropdown}
        alt="Dropdown"
      />
    </components.DropdownIndicator>
  );
};

const DropdownIndicatorV3 = (props): ReactElement => {
  return (
    <components.DropdownIndicator {...props}>
      {" "}
      <img src={DropdownV3} alt="Dropdown" />
    </components.DropdownIndicator>
  );
};

const getCommonProps = (props, fieldApi): Record<string, unknown> => {
  const { placeholderText } = props;
  const {
    preChangeHook,
    onChange,
    onBlur,
    name,
    typeProperty = "cardtype",
    isClearable = true,
    selectV3,
    isMulti = false,
    placeholder = {
      text: placeholderText || "Select...",
      image: "",
      styles: {},
    },
    formSubmitError,
    options,
    valueProperty,
    labelProperty,
    showCreateNew = false,
    createNewLabel = "Create new",
    createNewValue = "create-new",
    styles = {},
    isCreatable = false,
    ...rest
  } = props;
  const { form, meta, field: fieldApiField } = fieldApi;
  const { setFieldValue, setFieldTouched } = form;
  const { error, touched } = meta;
  const { value } = fieldApiField;

  const isInvalid = (touched && error) || (formSubmitError && error);
  const enableIcons = !!props.iconField;

  const selectOptions = options.map((option) => ({
    value: option[valueProperty],
    label: option[labelProperty],
    type: option[typeProperty],
    path: option.path ? option.path : null,
    authorization_type: option.authorization_type || [],
    has_authorization_setup: option.has_authorization_setup || false,
    isDisabled: option.isDisabled || false,
  }));

  if (enableIcons) {
    selectOptions.forEach((obj: Record<string, unknown>, idx) => {
      // eslint-disable-next-line no-param-reassign
      obj[props.iconField] = options[idx][props.iconField];
    });
  }

  if (showCreateNew) {
    selectOptions.unshift({
      value: createNewValue,
      label: createNewLabel,
    });
  }

  let valueOption = selectOptions.find(
    (option) => String(option.value) === String(value)
  );
  if (!valueOption && value && isCreatable) {
    valueOption = {
      value,
      label: value,
    };
  }

  if (isMulti && Array.isArray(value)) {
    valueOption = selectOptions.filter(
      (option) => value.indexOf(option.value) !== -1
    );
  }
  const commonProps: Record<string, unknown> = {
    value: valueOption || "",
    options: selectOptions,
    onChange: (option) => {
      const newValue = option ? option.value : null;
      let valueShouldChange = true;
      if (preChangeHook) {
        valueShouldChange = preChangeHook(isMulti ? option : newValue);
      }

      /** preHookChange should return true to allow changes */
      if (valueShouldChange && isMulti) {
        const values = option.map((op) => op.value);
        setFieldValue(name, values);
        if (onChange) {
          onChange(values);
        }
      }

      if (valueShouldChange && !isMulti) {
        setFieldValue(name, newValue);
        if (onChange) {
          if (option && option.path) {
            onChange(option.path, newValue);
          } else if (option && option.authorization_type) {
            onChange(
              option.authorization_type,
              option.has_authorization_setup,
              newValue
            );
          } else {
            onChange(newValue);
          }
        }
      }
    },
    onBlur: (e) => {
      setFieldTouched(name);
      if (onBlur) {
        onBlur(e);
      }
    },

    isClearable,
    styles: {
      loadingIndicator: () => {
        return {
          paddingTop: "15px",
          paddingRight: "15px",
          "&>span": {
            height: "6px",
            width: "6px",
          },
        };
      },
      control: (base, state) => {
        const { control: customStyles = {} } = styles;
        let newStyles;
        if (typeProperty !== "type") {
          newStyles = {
            ...base,
            borderColor: "#e2e0db",
            minHeight: 35,
            height: 35,
            fontSize: "1rem",
            fontWeight: 400,
            color: "#35434b",
            borderWidth: 1,
            "&:hover": {
              borderColor: "#e2e0db",
            },
            paddingTop: "0px",
          };
        } else {
          newStyles = {
            ...base,
            borderColor: "#e2e0db",
            minHeight: 24,
            height: 24,
            fontSize: "1rem",
            fontWeight: 400,
            color: "#35434b",
            borderWidth: 1,
            "&:hover": {
              borderColor: "#e2e0db",
            },
          };
        }

        if (isInvalid && props.dontSendError !== true) {
          newStyles.borderColor = "#dc3545";
          newStyles["&:hover"] = {
            borderColor: "#dc3545",
          };

          if (state.isFocused) {
            newStyles.boxShadow = "0 0 0 0.2rem rgba(220, 53, 69, 0.25)";
          }
        } else if (state.isFocused) {
          newStyles.boxShadow = null;
          newStyles.borderColor = "#509cf6";
          newStyles["&:hover"] = {
            borderColor: "#509cf6",
          };
        }

        return {
          ...newStyles,
          ...customStyles,
        };
      },
      valueContainer: (base) => {
        const { valueContainer: customStyles = {} } = styles;
        let newStyles = {};
        newStyles = {
          ...base,
          height: 34,
          fontSize: "0.875rem",
        };

        return {
          ...newStyles,
          ...customStyles,
        };
      },
      menu: (base) => ({
        ...base,
        marginTop: 0,
        zIndex: 10,
        borderRadius: "0 0 4px 4px",
      }),
      option: (base) => ({
        ...base,
        fontSize: "1rem",
      }),
      placeholder: (base) => {
        return {
          ...base,
          color: "#999999",
          fontSize: "0.875rem",
          ...placeholder.styles,
        };
      },
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    },
  };
  const isFixed = props.isFixed ? props.isFixed : false;
  const closeOnScroll = props.closeOnScroll ? props.closeOnScroll : false;
  const containerId = props.containerId ? props.containerId : "";

  if (isFixed) {
    if (containerId) {
      commonProps.menuPortalTarget = document.getElementById(containerId);
    } else {
      commonProps.menuPortalTarget = document.body;
    }
  }
  if (closeOnScroll && containerId) {
    commonProps.closeMenuOnScroll = (event) => {
      const containerEl = document.getElementById(containerId);
      if (containerEl && containerEl.contains(event.target)) {
        return false;
      }
      return true;
    };
  }

  if (
    (rest.defaultValue && commonProps.value === "") ||
    rest.defaultValue === commonProps.value
  ) {
    delete commonProps.value;
  }
  return commonProps;
};

const getCustomComponents = (props): Record<string, unknown> => {
  const {
    typeProperty = "cardtype",
    selectV3,
    optionsWithType,
    customDropdownIndicatorV3,
    showCustomValueContainer,
  } = props;
  const enableIcons = !!props.iconField;
  const customComponents: Record<string, unknown> = {};

  if (enableIcons) {
    customComponents.Option = IconOption;
    customComponents.SingleValue = IconSelectedSingleValue;
  }

  if (props.disableSeparator) {
    customComponents.IndicatorSeparator = () => null;
  }
  if (typeProperty === "type") {
    customComponents.ClearIndicator = ClearIndicator;
    customComponents.DropdownIndicator = DropdownIndicator;
  }
  if (selectV3) {
    customComponents.ClearIndicator = ClearIndicatorv3();
    customComponents.DropdownIndicator = DropdownIndicator;
  }
  if (props.customDropdownIndicator || customDropdownIndicatorV3) {
    customComponents.DropdownIndicator = customDropdownIndicatorV3
      ? DropdownIndicatorV3
      : DropdownIndicator;
  }
  if (optionsWithType) {
    customComponents.Option = TypeOption;
  }
  if (showCustomValueContainer) {
    customComponents.ValueContainer = CustomValueContainer;
  }
  return customComponents;
};

const reactSelect = (props, forwardedRef, fieldApi): ReactElement => {
  const { placeholderText } = props;
  const {
    preChangeHook,
    onChange,
    onBlur,
    name,
    options,
    valueProperty,
    labelProperty,
    typeProperty = "cardtype",
    selectV3,
    isMulti = false,
    placeholder = {
      text: placeholderText || "Select...",
      image: "",
      styles: {},
    },
    validate,
    optionsWithType,
    formSubmitError,
    customDropdownIndicatorV3,
    showEditIcon,
    onEdit,
    isSearchable = true,
    readOnly = false,
    ...rest
  } = props;
  let placeholderImg;
  if (typeProperty === "type") {
    placeholderImg = (
      <span
        style={{
          display: "inline-grid",
          gridTemplateColumns: "18px 1fr",
          alignItems: "center",
        }}
      >
        <img
          style={{ borderRadius: "50%" }}
          src={placeholder.image}
          alt="icon"
        />
        {placeholder.text}
      </span>
    );
  }
  const isFieldClearable = !props.isClearable ? props.isClearable : true;
  const isFixed = props.isFixed ? props.isFixed : false;

  return (
    <div
      className={`react-select-div ${readOnly ? "read-only" : ""}`}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      <ReactSelect
        menuPosition={isFixed ? "fixed" : "absolute"}
        ref={forwardedRef ? forwardedRef.componentRef : null}
        className={`${props.className} c-select`}
        isClearable={isFieldClearable}
        components={getCustomComponents(props)}
        placeholder={
          typeProperty === "type" ? placeholderImg : placeholder.text
        }
        isMulti={isMulti}
        classNamePrefix={typeProperty === "type" ? "card-select" : ""}
        {...getCommonProps(props, fieldApi)}
        {...rest}
        onKeyDown={(e) => {
          if (e.keyCode === 13 && typeProperty === "type") {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        isSearchable={isSearchable}
      />
      {showEditIcon && (
        <span
          tabIndex={0}
          role="button"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEdit();
            }
          }}
          onClick={() => {
            onEdit();
          }}
        >
          <img
            src={EditOptionIcon}
            className="edit-option-icon"
            alt="edit-option-icon"
          />
        </span>
      )}
    </div>
  );
};

const renderField = (props, forwardedRef) => {
  return (fieldApi) => {
    const { placeholderText } = props;
    const {
      isCreatable = false,
      isMulti = false,
      placeholder = {
        text: placeholderText || "Select...",
        image: "",
        styles: {},
      },
      formSubmitError,
      formFeedbackClass = false,
      ...rest
    } = props;

    const { meta } = fieldApi;
    const { error, touched } = meta;

    const isInvalid = (touched && error) || (formSubmitError && error);
    const isFieldClearable = !props.isClearable ? props.isClearable : true;
    return (
      <div>
        {isCreatable ? (
          <ReactCreatableSelect
            menuPosition="absolute"
            ref={forwardedRef || null}
            className={props.className}
            isClearable={isFieldClearable}
            components={getCustomComponents(props)}
            placeholder={placeholder.text}
            isMulti={isMulti}
            {...getCommonProps(props, fieldApi)}
            {...rest}
          />
        ) : (
          reactSelect(props, forwardedRef, fieldApi)
        )}
        {isInvalid && props.dontSendError !== true && (
          <FormFeedback
            className={
              formFeedbackClass
                ? "d-block fieldAcTestSelectFeedback"
                : "d-block"
            }
          >
            {typeof error === "string" ? error : ""}
          </FormFeedback>
        )}
      </div>
    );
  };
};

interface SelectOptions {
  label: string;
  value: unknown;
  [otherOptions: string]: unknown;
}

export interface SelectFieldProps {
  isFastField?: boolean;
  name: string;
  // eslint-disable-next-line react/no-unused-prop-types
  options: SelectOptions[] | unknown[];
  // eslint-disable-next-line react/no-unused-prop-types
  isLoading?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  isDisabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  classNamePrefix?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  valueProperty?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  labelProperty?: string;
  validate?(value): string;
  [otherOptions: string]: unknown;
}

const Select = React.forwardRef((props: SelectFieldProps, forwardedRef) => {
  const { isFastField, name, validate } = props;
  return isFastField ? (
    <FastField name={name} validate={validate}>
      {renderField(props, forwardedRef)}
    </FastField>
  ) : (
    <Field name={name} validate={validate}>
      {renderField(props, forwardedRef)}
    </Field>
  );
});

Select.defaultProps = {
  isFastField: false,
  validate: () => "",
  isLoading: false,
  isDisabled: false,
  labelProperty: "label",
  valueProperty: "id",
};

export default Select;
